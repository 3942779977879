@import "app-variables";
@import "app-mixins";
@import "color-default";
@import "bootstrap.scss";
@import "icomoon";
@import "animate";
@import "../../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css";
@import "../../node_modules//ngx-toastr/toastr.css";
@import "../../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
@import "@ag-grid-community/all-modules/dist/styles/ag-theme-material.css";
@import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-treegrid/styles/material.css";

// Small devices (landscape phones, 576px and up)
// @media (min-width: 576px) { ... }
// @include media-breakpoint-up(sm) { ... }

// Medium devices (tablets, 768px and up)
// @media (min-width: 768px) { ... }
// @include media-breakpoint-up(md) { ... }

// Large devices (desktops, 992px and up)
// @media (min-width: 992px) { ... }
// @include media-breakpoint-up(lg) { ... }

// Extra large devices (large desktops, 1200px and up)
// @media (min-width: 1200px) { ... }
// @include media-breakpoint-up(xl) { ... }

// Nunito:300,400,600,700,800,900

.float-right {
  float: right;
}

html,
body {
  height: 100%;
}

html {
  font-family: "Lato", sans-serif !important;
}

body {
  margin: 0;
  font-family: "Lato", sans-serif !important;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $body-color;
  text-align: left;
  font-weight: 400;
  background-color: $body-bg;
}

.mat-form-field {
  font-family: "Lato", sans-serif !important;
}

.light-theme {
  .container-fluid:fullscreen {
    background-color: #fff;
    overflow: auto;
  }
}

a,
.btn {
  cursor: pointer !important;
}

.btn {
  &:focus {
    outline: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 400;
}

//new theme css
.sideBar-wrapper {
  display: flex;

  .sideBar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 70px;
    padding-top: 15px;
    z-index: 9999;
    background: rgb(73, 14, 84);
    background: linear-gradient(
      180deg,
      rgba(73, 14, 84, 1) 0%,
      rgba(130, 3, 145, 1) 100%
    );

    @media (max-width: 767px) {
      width: 50px;
    }

    .logo-img {
      max-width: 50px;
      margin: 0 10px;
      margin-bottom: 20px;

      @media (max-width: 767px) {
        max-width: 40px;
        margin: 0px 5px;
        margin-bottom: 10px;
      }
    }

    ul {
      padding: 0;
      list-style-type: none;

      li {
        cursor: pointer;
        padding: 16px 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        .material-icons {
          font-size: 40px;
        }
      }
    }
  }
}

.mat-drawer {
  width: 240px;
  color: #fff;
  z-index: 9999;
  box-shadow: none;
  border-right: none;
  border-right: 1px solid #666666;

  @media (max-width: 767px) {
    width: 200px;
  }

  &.main-sidenav {
    position: fixed !important;
  }

  .mat-expansion-panel {
    margin: 0;
    background-color: transparent;
    box-shadow: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid #b9b9b9;

    .mat-expansion-panel-content {
      background-color: transparent;

      .mat-list-base .mat-list-item,
      .mat-list-base .mat-list-option {
        height: auto;
        padding: 5px;

        .mat-list-item-content {
          padding-left: 0px;

          &:hover {
            .icon-more-data {
              left: 3px;
              transition: all ease 0.4s;
              // color: #1abc9c;
            }
          }
        }
      }
    }

    .mat-expansion-panel-header {
      padding: 0 15px;
      // .mat-expansion-indicator {
      //   &:after {
      //     font-family: "icomoon" !important;
      //     content: "\e972";
      //     border-width: inherit;
      //     border-style: inherit;
      //     transform: rotate(0deg);
      //     color: #fff;
      //     font-size: 13px;
      //   }
      // }

      // &.mat-expanded {
      //   padding: 0 15px;
      //   background-color: #1abc9c;
      //     color: #fff;

      //     &:after {
      //       background: transparent none repeat scroll 0 0;
      //       border-bottom: 10px solid transparent;
      //       border-right: 8px solid #e9e9e9;
      //       border-top: 10px solid transparent;
      //       display: block;
      //       float: right;
      //       height: 0;
      //       position: absolute;
      //       right: 0;
      //       content: "";
      //     }
      //   &:hover{
      //     background-color: #1abc9c;
      //     color: #fff;

      //     &:after {
      //       background: transparent none repeat scroll 0 0;
      //       border-bottom: 10px solid transparent;
      //       border-right: 8px solid #e9e9e9;
      //       border-top: 10px solid transparent;
      //       display: block;
      //       float: right;
      //       height: 0;
      //       position: absolute;
      //       right: 0;
      //       content: "";
      //     }
      //   }
      //   .mat-expansion-indicator {
      //     &:after {
      //       font-family: "icomoon" !important;
      //       content: "\e971";
      //       border-width: inherit;
      //       border-style: inherit;
      //       transform: rotate(0deg);
      //       color: #fff;
      //       font-size: 13px;
      //     }
      //   }
      // }
    }

    .mat-expansion-panel-body {
      padding: 0 !important;

      .mat-list-base {
        // padding: 0;
      }
    }

    .mat-expansion-panel-header-title {
      font-size: 13px !important;
      margin-right: 5px;
      font-weight: normal !important;

      .material-icons {
        font-size: 20px;
        padding-right: 10px;
      }
    }
  }

  .single-link {
    padding: 0;
    max-height: 75px;
    height: 75px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #b9b9b9;

    .material-icons {
      font-size: 20px;
      padding-right: 10px;
    }

    // &:hover {
    //   background-color: #1abc9c;
    //   color: #fff;
    //   transition: all ease-in-out 0.3s;

    //   &:after {
    //     background: transparent none repeat scroll 0 0;
    //     border-bottom: 10px solid transparent;
    //     border-right: 8px solid #e9e9e9;
    //     border-top: 10px solid transparent;
    //     display: block;
    //     float: right;
    //     height: 0;
    //     position: absolute;
    //     right: 0;
    //     content: "";
    //   }
    // }

    .mat-list-item {
      font-weight: 400;
      font-size: 14px;

      &:hover {
        background-color: transparent;
      }
    }

    .mat-list-item-content {
      padding-left: 15px !important;
    }
  }

  .company-user-name {
    height: 75px;
    display: flex;
    align-items: center;
    padding: 0;
    border-bottom: 1px solid #b9b9b9;

    p {
      font-weight: bold;
      margin: 0;
      font-size: 16px;
      color: #b732af;
    }

    .mat-button {
      width: 100%;
      height: 100%;
      background-color: #fff;
      border-radius: 0;
      padding: 0 15px;

      &[aria-expanded="true"] {
        background-color: #960098;

        p {
          color: #fff;
        }

        .icon-user {
          color: #fff !important;
        }

        .material-icons {
          color: #fff !important;
          transform: rotate(90deg);
        }
      }

      .mat-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .icon-user {
          font-size: 30px;
          color: #000;
        }

        .material-icons {
          color: #7d7d7d;
          font-size: 40px;
        }
      }
    }

    .mat-select {
      .mat-select-value-text {
        color: #fff !important;
        font-weight: normal !important;
      }
    }
  }

  .arrow {
    display: flex;
    align-items: center;
    padding-left: 15px;
    position: relative;
    font-size: 13px;
    font-weight: normal;

    i {
      font-size: 10px;
      padding-right: 10px;
      position: absolute;
      left: 0;
      transition: all ease 0.5s;
    }
  }
}

.mat-accordion {
  .mat-expansion-panel-header {
    padding: 0 50px 0 80px;

    @media (max-width: 767px) {
      padding: 0 10px 0 10px;
    }
  }
}

.mat-expansion-panel {
  box-shadow: none !important;
  border-radius: 0 !important;

  .mat-expansion-panel-header {
    font-family: "Lato", sans-serif;
    border-radius: 0;

    .mat-expansion-panel-header-title {
      color: #000;
      font-weight: 600;
      font-size: 18px;
      font-family: "Lato", sans-serif;
    }

    .mat-expansion-indicator {
      &:after {
        color: #070a1b;
      }
    }
  }
}

.darker {
  text-align: right;
}

.light-theme {
  input.mat-input-element {
    color: #232323;
  }

  .sideBar {
    ul {
      li {
        &:hover,
        &.active {
          background-color: #fff;
        }

        .material-icons {
          color: #000;
        }
      }
    }
  }

  .ag-theme-material {
    .ag-header {
      background-color: #f3f3f3;

      .ag-header-cell {
        background-color: #f3f3f3;
        border-right: 1px solid #ee4a6f;
      }

      .ag-header-cell-label .ag-header-cell-text {
        color: #232323;
      }
    }

    .ag-header-group-text {
      color: #232323;
    }

    .ag-icon {
      color: #ee4a6f;
    }

    .ag-header-viewport {
      border-left: none;
      border-bottom: 2px solid #ee4a6f;
    }
  }

  .user-page {
    .mat-form-field {
      .mat-icon {
        color: #000;
      }
    }
  }

  .e-treeview .e-list-item div.e-icons::before {
    color: #000;
  }

  .e-list-parent {
    background-color: #fff;

    .e-list-item {
      background-color: #f9f9f9;
    }
  }

  .right-chat,
  .response-inner {
    span {
      color: #00000073;
    }
  }

  .right-chat .right-icon mat-icon,
  .right-chat .right-icon p {
    color: #000;
  }

  .darker {
    background-color: #ef4b6e33;

    p {
      color: #232323;
    }
  }

  .darker:before {
    border-color: #fcdbe2 transparent transparent transparent;
  }

  .response-back {
    background-color: #f3f3f3;
  }

  .response-back:before {
    border-color: transparent #f3f3f3 transparent transparent;
  }

  .mat-drawer-container {
    background-color: #fff !important;
    min-height: 100vh;
    width: 100%;
  }

  .mat-drawer {
    background-color: #fff;

    .mat-expansion-panel {
      .mat-expansion-panel-header {
        .mat-expansion-indicator:after {
          color: #232323;
        }
      }
    }

    .mat-expansion-panel-header-title {
      color: #232323 !important;
    }

    .single-link {
      .mat-list-item {
        color: #232323;
      }
    }

    .arrow {
      color: #232323;
    }
  }

  .mat-accordion {
    .mat-expansion-panel-header {
      background-color: #f4f4f4;
      box-shadow: 0px 4px 7px rgba(63, 63, 63, 0.1);
      z-index: 1;
    }
  }

  &.mat-expanded {
    transition: all ease-in-out 0.3s;
    background-color: #f4f4f4;

    &:focus,
    &:hover {
      transition: all ease-in-out 0.3s;
      background-color: #f4f4f4;
    }

    .mat-expansion-panel-header-title {
      transition: all ease-in-out 0.3s;
      color: #232323;
    }
  }

  .mat-expansion-panel:not(.mat-expanded)
    .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
    // box-shadow: 0px 4px 7px rgba(63, 63, 63, 0.1);
    transition: all ease-in-out 0.3s;

    .mat-expansion-panel-header-title {
      transition: all ease-in-out 0.3s;
      color: #000;
    }
  }

  .response-inner-div {
    color: #000;
  }
}

.dark-theme {
  background-color: #000;

  .summary-form-view {
    b,
    label {
      color: #fff;
    }
  }

  #fb-forecast-new-planner {
    .item-wrapper.fb-forecast-planner {
      h3 {
        color: #fff;
      }
    }

    .item-wrapper {
      .form-group {
        label {
          color: #fff;
        }
      }
    }
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #fff !important;
  }

  .mat-slide-toggle-content,
  input.mat-input-element,
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: #fff;
  }

  .mat-drawer .company-user-name .mat-button {
    background-color: #000;
  }

  .mat-drawer .company-user-name .mat-button .mat-button-wrapper .icon-user {
    color: #fff;
  }

  .mat-drawer
    .company-user-name
    .mat-button
    .mat-button-wrapper
    .material-icons {
    color: #fff;
  }

  .page-head,
  .page-head-cred {
    background-color: #000;

    .mat-form-field {
      background-color: #000;
    }

    .material-icons {
      color: #fff;
    }
  }

  .sideBar {
    ul {
      li {
        &:hover,
        &.active {
          background-color: #000;
        }

        .material-icons {
          color: #fff;
        }
      }
    }
  }

  .ag-theme-material {
    .ag-header {
      background-color: #000;

      .ag-header-cell {
        background-color: #000;
        border-right: none;
      }

      .ag-header-cell-label .ag-header-cell-text {
        color: #fff;
      }
    }

    .ag-header-group-text {
      color: #fff;
    }

    .ag-icon {
      color: #ee4a6f;
    }

    .ag-header-viewport {
      border-left: none;
      border-bottom: 2px solid #ee4a6f;
    }

    .ag-body-viewport .ag-center-cols-clipper {
      background-color: #282827;
    }

    .ag-center-cols-viewport .ag-row .ag-cell {
      color: #fff;
    }
  }

  .user-page {
    .mat-form-field {
      .mat-icon {
        color: #fff;
      }
    }
  }

  .e-treeview .e-list-item div.e-icons::before {
    color: #fff;
  }

  .menu .menu-toggle {
    background-color: #fff;
  }

  .menu {
    .menu-toggle {
      &.menu-show {
        .icon-more-vertical:before {
          color: #000;
        }
      }

      .icon-more-vertical {
        color: #000;
      }
    }
  }

  .e-list-parent {
    background-color: #282827;

    .e-list-item {
      background-color: #000;
    }
  }

  .bot-accordian .e-list-parent .e-text-content .e-list-text .action-panel p {
    color: #fff;
  }

  .bot-accordian .action-panel.top .form-heading {
    color: #fff;
  }

  .mat-expansion-panel {
    background: #282827;
  }

  .right-chat,
  .response-inner {
    span {
      color: #fff;
    }
  }

  .right-chat .right-icon mat-icon,
  .right-chat .right-icon p {
    color: #fff;
  }

  .darker {
    background-color: #000;
    border: 1px solid #ec4173;

    p {
      color: #fff;
    }
  }

  .darker:before {
    border-color: #000 transparent transparent transparent;
  }

  .response-back {
    background-color: #000;
    border: 1px solid #9b0b97;
  }

  .response-back:before {
    border-color: transparent #000 transparent transparent;
  }

  .response-back:before {
    border-color: transparent #000 transparent transparent;
  }

  .response-inner-div {
    color: #fff;
  }

  .mat-drawer-container {
    background-color: #282827 !important;
    min-height: 100vh;
    width: 100%;
  }

  .mat-drawer {
    background-color: #000;

    .mat-expansion-panel {
      .mat-expansion-panel-header {
        background-color: #000;

        .mat-expansion-indicator:after {
          color: #fff;
        }
      }
    }

    .mat-expansion-panel-header-title {
      color: #fff !important;
    }

    .single-link {
      .mat-list-item {
        color: #fff;
      }
    }

    .arrow {
      color: #fff;
    }
  }

  .mat-accordion {
    .mat-expansion-panel-header {
      background-color: #000 !important;
      box-shadow: 0px 4px 7px rgba(63, 63, 63, 0.1);
      z-index: 1;
    }
  }

  &.mat-expanded {
    transition: all ease-in-out 0.3s;
    background-color: #000;

    &:focus,
    &:hover {
      transition: all ease-in-out 0.3s;
      background-color: #000;
    }

    .mat-expansion-panel-header-title {
      transition: all ease-in-out 0.3s;
      color: #fff;
    }
  }

  .mat-expansion-panel:not(.mat-expanded)
    .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
    box-shadow: 0px 4px 7px rgba(63, 63, 63, 0.1);
    transition: all ease-in-out 0.3s;

    .mat-expansion-panel-header-title {
      transition: all ease-in-out 0.3s;
      color: #000;
    }
  }

  .mat-expansion-panel {
    .mat-expansion-panel-header {
      .mat-expansion-panel-header-title {
        color: #fff;
      }

      .mat-expansion-indicator:after {
        color: #fff;
      }
    }
  }

  .right-sidenav #rightSidenav .mat-drawer-content {
    background-color: #000;
  }
}

.form-control {
  background-color: #f6f6f6;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.btn {
  border-radius: 34px;
  font-size: 16px;
  color: $white;
  overflow: hidden;
  transition: all 0.5s;
  position: relative;

  &.btn-primary {
    @include button-variant(
      $primary,
      $primary,
      $hover-background: darken($primary, 7.5%),
      $hover-border: darken($primary, 10%),
      $active-background: darken($primary, 10%),
      $active-border: darken($primary, 12.5%)
    );
    color: #fff;
    background-color: $primary;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;

    &:disabled,
    &.disabled {
      color: #fff;
      background-color: #6c757d;
      border-color: #6c757d;
      box-shadow: 0 0.5px 4px 0 #6c757d;
    }

    .mat-button-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    i {
      font-size: 18px;
      color: #fff;
      padding-left: 30px;
    }
  }

  &.btn-secondary {
    @include button-variant(
      $secondary,
      $secondary,
      $hover-background: darken($secondary, 7.5%),
      $hover-border: darken($secondary, 10%),
      $active-background: darken($secondary, 10%),
      $active-border: darken($secondary, 12.5%)
    );
    color: $white;
    background-color: $secondary;

    &:disabled,
    &.disabled {
      color: #fff;
      background-color: #6c757d;
      border-color: #6c757d;
      box-shadow: 0 0.5px 4px 0 #6c757d;
    }
  }

  &.btn-light {
    background-color: #f8f9fb;
    color: #525261;
    border: none;
  }

  &.btn-outline-danger {
    border: 2px solid #ff5a59;
    color: #ff5a59;
    box-shadow: 0 3px 8px 0 rgba(#ff5a59, 0.68);

    &:hover {
      color: #fff;
    }
  }

  &.btn-outline-default {
    border: 2px solid #979797;
    color: #181b37;
    box-shadow: none;

    &:hover {
      color: #181b37;
    }
  }

  &.btn-fab {
    padding: 0;
    border-radius: 50%;
    line-height: 0;
    width: 58px;
    height: 56px;
    box-shadow: 0 3px 8px 0 rgba(15, 135, 56, 0.68);
    background-image: linear-gradient(
      to left,
      transparent,
      transparent 50%,
      #0f8738 50%,
      #0f8738
    );
    background-position: 100% 0;
    background-size: 200% 100%;
    transition: all 0.25s ease-in;

    &:hover {
      background-position: 0 0;
    }
  }

  &.orange {
    background-color: #0f8738;
    box-shadow: 0 3px 8px 0 rgba(15, 135, 56, 0.68);

    &:hover {
      color: #fff;
    }

    i {
      color: $white;
      font-size: 10px;
    }
  }

  &.btn-adjust {
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }
}

.action-panel {
  padding: 10px;

  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: flex-end;
  }

  .btn {
    width: 100%;
    margin-bottom: 10px;

    @include media-breakpoint-up(md) {
      width: auto;
      margin-bottom: 0;
    }

    + .btn {
      margin-left: 10px;

      @media only screen and (max-width: 767px) {
        margin-left: 0;
        bottom: 0;
      }
    }
  }

  .book-appointment {
    bottom: 72px;

    @media only screen and (max-width: 991px) {
      bottom: 0;
    }

    @media only screen and (max-width: 767px) {
      bottom: 0;
    }
  }
}

// implement css Asad Ilyas
.action-panels {
  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: flex-end;
  }

  .btn {
    width: 100%;
    margin-bottom: 10px;

    @include media-breakpoint-up(md) {
      width: auto;
      margin-bottom: 0;
    }

    + .btn {
      margin-left: 10px;

      @media only screen and (max-width: 767px) {
        margin-left: 0;
        bottom: 0;
      }
    }
  }

  .book-appointment {
    bottom: 72px;

    @media only screen and (max-width: 991px) {
      bottom: 0;
    }

    @media only screen and (max-width: 767px) {
      bottom: 0;
    }
  }
}

.icomoon-font {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
}

.action-btn {
  text-align: center;
  position: absolute;
  top: 50px;
  right: 23px;
  padding: 0;
  cursor: pointer;

  a {
    vertical-align: middle;
    width: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon-more {
      font-size: 22px;
    }
  }
}

.icon-close.small {
  font-size: 10px;
}

.action-btn {
  a {
    vertical-align: middle;
    width: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.text-center {
  text-align: center;
}

.required {
  .form-control {
    border-color: rgba($color: #d0021b, $alpha: 0.62);
  }

  .error-msg {
    font-size: 12px;
    margin: 0;
    color: rgba($color: #d0021b, $alpha: 0.62);
  }

  &.floating-message {
    position: relative;

    .error-msg {
      position: absolute;
      bottom: -20px;
      left: 0;
      z-index: 1;
    }
  }
}

.btn {
  &.btn-round {
    border: transparent;
    height: 40px;
    border-radius: 20px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
  }
}

.arcadian-panel,
.accordian {
  .complete-panel,
  .accordion-inner {
    margin-bottom: 10px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.17), 0 2px 1px 0 rgba(0, 0, 0, 0.03);

    .btn {
      font-size: 12px;
      line-height: 16px;
      color: #69747f;
      opacity: 0.7;
      padding: 6px 20px;
      height: 50px;
      background-color: #f5f8fa;
      border-radius: inherit;
    }

    .card {
      border: transparent;
    }

    .status-accordian {
      float: right;
      margin-top: 3px;

      &::before {
        @extend .icomoon-font;
        content: "\e916";
        font-size: 10px;
        display: none;
      }

      &:after {
        @extend .icomoon-font;
        content: "\e91f";
        font-size: 10px;
      }

      &.minus {
        &::after {
          display: none;
        }

        &::before {
          @extend .icomoon-font;
          content: "\e916";
          font-size: 10px;
        }
      }
    }

    .panel-heading {
      border-radius: 2px 2px 0 0;
      background-color: #f5f8fa;
      height: 49px;
      padding: 16px 20px;

      .btn-link {
        color: rgba(#69747f, 0.7);

        font-size: 12px;
        line-height: 16px;
        text-decoration: none;
        padding: 0;
        margin: 0;
        height: inherit;
      }
    }

    &.panel-open {
      .panel-heading {
        color: red;

        .status-accordian {
          &:after {
            @extend .icomoon-font;
            content: "\e916";
            font-size: 10px;
          }
        }
      }
    }

    .card-body {
      padding: 26px 19px;

      .card-inner {
        padding-bottom: 10px;
      }
    }
  }
}

.form-group {
  position: relative;

  > label {
    display: block;
    width: 100%;
    color: #4a4a4a;
    font-size: 12px;
    line-height: 19px;
  }
}

.view-specific {
  .list-view {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      padding: 15px 30px 15px 20px;
      border-bottom: 1px solid #d4d6da;
      background: $white;
      height: 55px;
      font-size: 18px;
      position: relative;
      cursor: pointer;
      color: #525261;

      &:last-child {
        border-bottom: transparent;

        &::before {
          content: "";
        }
      }

      &:hover,
      &.active {
        background-color: #f5f5f5;
        color: #121e6a;
      }

      &:before {
        @extend .icomoon-font;
        position: absolute;
        right: 23px;
        top: 22px;
        display: block;
        width: 8px;
        height: 13px;
        content: "\e925";
        font-size: 10px;
      }
    }

    &.half-panel {
      li.head {
        width: 100%;
        text-align: left;
      }
    }
  }

  .white-section {
    margin-bottom: 10px;
    box-shadow: 0 2px 4px 0 rgba(203, 203, 203, 0.5);
    background: #fff;

    &.widget-steps {
      border: 1px solid #d4d6da;

      @media (min-width: 769px) {
        display: flex;
        flex-direction: row;
      }

      .columns {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        position: relative;
        overflow: hidden;
        display: block;

        @media (min-width: 769px) {
          flex-grow: 1;
          width: 33%;
          border-right: 1px solid #d4d6da;
          overflow: visible;

          &:last-child {
            border-right: none;
          }
        }
      }

      .half {
        width: 50%;
        padding-left: 0;
        padding-right: 0;
        border-left: 1px solid #d4d6da;
        border-right: 1px solid #d4d6da;
      }
    }

    &.label-area {
      padding: 20px 15px 10px;

      @include media-breakpoint-up(md) {
        padding: 15px 20px 10px;
      }
    }

    &.manufacturer-panel-form-spacing {
      position: relative;

      .form-group {
        margin-bottom: 25px;
      }

      .action-panel {
        margin-top: 0;

        @include media-breakpoint-down(md) {
          margin-top: 20px;
        }

        .btn {
          @include media-breakpoint-up(md) {
            padding: 6px 58px;
          }
        }
      }

      .btn-edit-manufacturer {
        position: absolute;
        top: 15px;
        right: 15px;
        padding: 6px 30px;

        @include media-breakpoint-down(md) {
          padding: 0 12px;
          height: 22px;
          font-size: 12px;
        }
      }

      .required {
        .error-msg {
          @include media-breakpoint-up(md) {
            font-size: 10px;
          }

          @include media-breakpoint-up(lg) {
            font-size: 12px;
          }
        }
      }
    }
  }

  &.manufacturer-panel-form-in-row {
    position: relative;

    .form-group {
      margin-bottom: 25px;

      @include media-breakpoint-up(md) {
        margin-bottom: 16px;
      }
    }

    .action-panel {
      margin: 0;
    }

    .floating-button {
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }
}

.label-area {
  padding: 15px 10px 10px;

  label {
    font-size: 14px;
    color: #13264d !important;
  }

  h2 {
    color: #181b37;
    font-size: 28px;
    font-weight: 400;
  }
}

label {
  margin-bottom: 5px;
  display: block;

  &.big {
    color: #232323;
    font-size: 16px;
    letter-spacing: -0.13px;
  }
}

.sidebar-inner {
  .fancy-scroll {
    .ps__rail-y:hover > .ps__thumb-y,
    .ps__rail-y:focus > .ps__thumb-y,
    .ps__thumb-y {
      width: 6px;
      background: darken($primary, 10%);
    }

    > .ps.ps--scrolling-y > .ps__rail-y,
    .ps .ps__rail-y:hover {
      background: transparent;
    }
  }

  .nav {
    min-height: 550px;

    @include media-breakpoint-up(md) {
      min-height: 0;
    }

    .report-menu-img {
      max-width: 24px;
      margin-right: 32px;
    }
  }
}

.form-control,
.btn {
  height: 40px;
}

.list-item time small {
  display: block;
  text-transform: uppercase;
}

.create-customer-wrap {
  .label-area {
    padding: 0;
  }

  .view-add-listing {
    margin-bottom: 14px;

    label {
      line-height: 16px;
      font-size: 12px;
      color: #4a4a4a;
      margin: 0;
      margin-bottom: 5px;

      @include media-breakpoint-up(md) {
        font-size: 14px;
      }
    }

    p {
      font-size: 14px;
      letter-spacing: -0.12px;
      line-height: 19px;
      color: #232323;
    }
  }

  .section-heading {
    button {
      right: 13px;
      top: -5px;
      height: 34px;
      width: 84px;
      padding: 0;
      box-shadow: 0 0.5px 4px 0 #0f8738;
    }
  }

  .content-customer-detail {
    padding-bottom: 6px;
  }

  .content-car-detail {
    margin-bottom: 20px;

    .price {
      padding: 0;
      font-size: 18px;
      letter-spacing: -0.15px;
      line-height: 25px;
      color: #232323;
      margin-bottom: 15px;
    }
  }

  .content-appointment-detail {
    margin-bottom: 23px;
  }

  .action-panel {
    overflow: hidden;
  }
}

.btn-wrap {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  margin-bottom: 20px;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  .btn {
    margin-left: 15px;

    @include media-breakpoint-up(md) {
      padding: 6px 10px;
    }

    @include media-breakpoint-up(lg) {
      padding: 6px 50px;
    }
  }
}

input:focus::-webkit-input-placeholder {
  color: transparent !important;
}

.checkpoints {
  width: 100%;
  margin-bottom: 10px;

  &.status-vehical {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &.passed .purchase-listing {
    i:before {
      color: #0db87e;
      content: "\e91e";
    }
  }

  &.failed .purchase-listing {
    i:before {
      color: #e24c4c;
      content: "\e940";
    }
  }

  .purchase-listing {
    margin-right: 10px;
    width: 20px;
    height: 20px;

    i {
      font-size: 20px;
      @extend .icomoon-font;
    }
  }

  label {
    margin-bottom: 0;
    font-size: 16px;
    color: #525261;
    line-height: 30px;
  }

  &.text {
    margin-top: 30px;

    h4 {
      font-size: 14px;
      line-height: 35px;
      color: #181b37;
      margin: 0;
    }

    p {
      font-size: 16px;
      line-height: 22px;
      color: #525261;
    }
  }

  &.attachment {
    h4 {
      font-size: 14px;
      line-height: 35px;
      color: #181b37;
      margin: 0;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        vertical-align: top;
        margin-right: 10px;
      }
    }
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.loadingDiv {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 1000px 104px;
  height: auto;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.btn-center {
  text-align: center;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.k-grid a.hyperLink {
  color: $primary;
}

.doc-group-listing {
  margin-bottom: 20px;

  h2 {
    margin-bottom: 20px;
    font-size: 20px;
    color: #525261;
    font-weight: 400;
  }

  .k-grid td .action-btn {
    top: 11px;
    right: 0;

    @include media-breakpoint-up(md) {
      right: 23px;
      top: 15px;
    }
  }

  .k-grid td .action-btn .icon-cross-slim {
    margin-top: 7px;
  }

  .action-area {
    @include media-breakpoint-up(md) {
      top: 0;
    }

    ul li,
    ul li a {
      height: 46px;
      width: auto;

      @include media-breakpoint-up(md) {
        height: 55px;
        width: 55px;
      }
    }

    ul li i {
      line-height: 46px;

      @include media-breakpoint-up(md) {
        line-height: 55px;
      }
    }
  }

  .file-icon-type {
    display: flex;
    align-items: center;
  }

  .k-grid-header {
    color: #69747f;
    background-color: #f5f8fa;
  }

  .doc-listing-content {
    .icon-image {
      color: #59a8ff;
      font-size: 30px;
    }

    .icon-pdf {
      color: #db3d45;
      font-size: 30px;
    }
  }
}

.accordian {
  .accordion-inner {
    .btn-finance {
      border-radius: 34px;
      font-size: 16px;
      color: #fff;
      overflow: hidden;
      transition: all 0.5s;
      position: relative;
      background-color: #0f8738;
      border-color: transparent;
      padding: 6px 25px;
      box-shadow: 0 3px 8px 0 rgba(15, 135, 56, 0.68);
      cursor: pointer;

      &:disabled,
      &.disabled {
        color: #fff;
        background-color: #6c757d;
        opacity: 0.65;
        box-shadow: 0 0.5px 4px 0 #6c757d;
      }
    }
  }
}

.btn-wrap {
  &.auction-area-btns {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;

    @media (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
    }

    .btn {
      margin-bottom: 10px;
      margin-left: 0;

      @media (min-width: 768px) {
        margin-left: 7px;
        margin-right: 7px;
        width: 100%;
        flex: 0 calc(50% - 15px);
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
        flex: 1;
        padding-left: 15px;
        padding-right: 15px;
        margin-left: 0;
      }

      @include media-breakpoint-up(xl) {
        flex: 0 auto;
        width: auto;
      }
    }
  }
}

.btn-light {
  .icon-export,
  .icon-print {
    margin-right: 5px;
  }
}

/* Algo Styles */
.text-center {
  .btn,
  .btn-default {
    width: 100%;
  }
}

.btn-show {
  .mat-icon-button {
    box-shadow: 0 3px 8px 0 rgba($secondary, 0.68);
    margin-left: 10px;
  }

  .material-icons {
    font-size: 50px;
    color: $secondary;
    cursor: pointer;
    margin-left: -4px;
  }

  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.form-heading {
  display: inline;
  padding-left: 10px;
}

.mat-form-field {
  width: 100% !important;
}

.cursor {
  cursor: pointer;
}

::ng-deep.mat-slide-toggle:checked {
  background-color: $primary !important;
}

.mat-slide-toggle.mat-checked {
  .mat-slide-toggle-bar {
    background-color: #e8e6e6 !important;
  }

  .mat-slide-toggle-thumb {
    background-color: #232323 !important;
  }
}

.custom-width {
  padding: 0 50px 70px 0;
  transition: all ease 0.4s;
}

.full-width {
  width: 100%;
  padding: 0 50px 100px 0;
  transition: all ease 0.4s;
}

.ag-theme-material {
  width: 100%;
  border: none;
  box-shadow: none;
  margin-bottom: 20px;
  font-family: "Lato", sans-serif;

  .ag-row {
    border-bottom: 1px solid #d2d2d2;
  }

  .ag-header {
    border-bottom: none;
  }

  .ag-body-viewport .ag-center-cols-clipper .ag-body-viewport.ag-layout-normal {
    background-color: transparent;
  }

  .ag-cell {
    line-height: 38px;
  }

  .ag-paging-panel {
    border-top: none;
    color: rgba(0, 0, 0, 0.54);
    height: 30px;
    padding: 0;
    background-color: transparent;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 20px;

    .ag-paging-row-summary-panel,
    .ag-paging-page-summary-panel {
      margin-left: 0;
      background-color: #fff;
      // height: 30px;
      align-items: center;
    }

    .ag-paging-row-summary-panel {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    .ag-paging-page-summary-panel {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  .ag-center-cols-viewport {
    .ag-row {
      .ag-cell {
        border-right: none;
        border-left: none;
      }
    }
  }

  .ag-row {
    border-color: transparent;
    border-style: none;

    &.ag-row-odd {
      background-color: transparent;
    }
  }

  .ag-ltr {
    .ag-cell {
      border-right: 1px solid #cfede6;
    }
  }

  .ag-header-viewport {
    border-left: 1px solid #cfede6;

    .ag-header-cell {
      border-right: 1px solid #cfede6;
    }
  }

  .ag-cell,
  .ag-header-cell,
  .ag-header-group-cell {
    padding-left: 24px;
    padding-right: 20px;
    border-color: #f9f9f9;
  }

  .ag-pinned-left-header {
    border-right: none;
    margin-left: -1px;
  }

  .ag-root-wrapper.ag-layout-normal {
    height: calc(85vh - 125px);
    width: 100%;
  }

  .ag-horizontal-left-spacer,
  .ag-horizontal-right-spacer {
    border-right: none;
    overflow: hidden;
    border-left: none;
  }

  .ag-header {
    .ag-header-cell-label .ag-header-cell-text {
      font-weight: 600;
      font-size: 14px;
    }
  }

  .ag-header-group-text {
    font-weight: 600;
    font-size: 14px;
  }

  .ag-body-horizontal-scroll-viewport::-webkit-scrollbar-track,
  .ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb,
  .ag-body-viewport.ag-layout-normal::-webkit-scrollbar-track,
  .ag-body-viewport.ag-layout-normal::-webkit-scrollbar-thumb,
  .ag-primary-cols-list-panel::-webkit-scrollbar-track,
  .ag-primary-cols-list-panel::-webkit-scrollbar-thumb,
  .ag-tool-panel-wrapper::-webkit-scrollbar-track,
  .ag-tool-panel-wrapper::-webkit-scrollbar-thumb {
    background-color: #f4f4f4;
    border-radius: 8px;
  }

  .ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb,
  .ag-body-viewport.ag-layout-normal::-webkit-scrollbar-thumb,
  .ag-primary-cols-list-panel::-webkit-scrollbar-thumb,
  .ag-virtual-list-viewport::-webkit-scrollbar-thumb,
  .ag-tool-panel-wrapper::-webkit-scrollbar-thumb {
    background-color: #a2a2a2;
    border-radius: 8px;
  }

  .ag-body-horizontal-scroll-viewport::-webkit-scrollbar,
  .ag-primary-cols-list-panel::-webkit-scrollbar {
    height: 10px;
  }

  .ag-body-viewport.ag-layout-normal::-webkit-scrollbar,
  .ag-primary-cols-list-panel::-webkit-scrollbar,
  .ag-virtual-list-viewport::-webkit-scrollbar,
  .ag-tool-panel-wrapper::-webkit-scrollbar {
    width: 10px;
    border-radius: 8px;
    background-color: #f4f4f4;
  }
}

.mat-card {
  .mat-card-header-text {
    margin: 0;
  }
}

.mat-card-header {
  .mat-card-title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    h3 {
      margin: 0;
    }
  }
}

.action-btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;

  .btn {
    margin-left: 10px;
  }
}

.create-customer-wrap {
  .mat-expansion-panel {
    .mat-expansion-panel-header {
      background: #eaf7f4 !important;
    }
  }
}

.mat-icon-button {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.page-head,
.page-head-cred {
  position: fixed;
  z-index: 9999;
  bottom: 0;
  right: 0;
  background-color: #fff;
  padding: 10px 100px 0 80px;
  box-shadow: 0px -1px 11px -2px rgba(0, 0, 0, 0.35);

  @media (max-width: 767px) {
    padding: 10px 0 0 0;
  }

  .mat-form-field {
    box-shadow: none;
    background: #fff;
    margin-bottom: 10px;
    padding: 0 15px;
    border-radius: 0;

    .mat-form-field-underline {
      height: inherit;
    }

    &.mat-form-field-appearance-legacy .mat-form-field-ripple {
      height: inherit;
    }

    .mat-form-field-wrapper {
      padding: 0;

      input:placeholder {
        color: #000;
        font-weight: bold;
      }
    }
  }

  form {
    width: 100%;
  }

  .mat-button {
    background-color: #ec4173;
    height: 50px;
    margin-top: -10px;
    box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.58);
    color: #fff;
    border-radius: 0;
  }

  .col-12 {
    padding-right: 15px;
    display: flex;
    align-items: center;
  }

  .material-icons {
    font-size: 40px !important;
    transform: rotate(180deg);
  }
}

.custom-width {
  .page-head {
    left: 320px;
    right: 10px;
  }

  .page-head-cred {
    left: 250px;
    right: 10px;
  }
}

.full-width {
  .page-head {
    left: 80px;
    right: 10px;

    @media (max-width: 767px) {
      left: 60px;
    }
  }

  .page-head-cred {
    left: 0px;
    right: 10px;
  }
}

#bot {
  .action-panel.top {
    padding-right: 35px;

    @media (max-width: 767px) {
      padding-right: 0;
    }
  }
}

.file-uploader {
  #default {
    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .btn {
      padding: 5px 12px;
      border-radius: 5px;
      width: 100%;
      margin-bottom: 10px;
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

      @include media-breakpoint-up(md) {
        margin-left: 10px;
        width: auto;
        margin-bottom: 0;
      }
    }

    p {
      display: none;
    }
  }
}

.tooltip {
  .arrow {
    &:before {
      display: none;
    }
  }

  .tooltip-inner {
    font-size: 16px;
  }
}

input::placeholder,
input:-webkit-autofill {
  font-size: 14px;
  line-height: 19px;
  color: #000;
  font-weight: bold;
}

.light-theme {
  .page-login {
    input.mat-input-element {
      color: #000;
    }
  }
}

.page-login {
  height: 100vh;
  background: #fff;
  overflow: hidden;

  &.forget-bg {
    background-image: url("../assets/images/login-bg.png");
    width: 100%;
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;

    .box-content .mat-form-field-flex i {
      color: #fff;
    }

    input::placeholder,
    input:-webkit-autofill {
      font-size: 20px !important;
      color: #fff !important;
      font-weight: 300 !important;
      background-color: transparent !important;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: #fff !important;
    }

    .box-content {
      @include media-breakpoint-up(lg) {
        padding: 0;
      }

      form {
        width: 100%;
      }

      .btn-wrapper {
        margin-top: 0;
      }
    }

    .row {
      height: auto;
    }
  }

  .login-left {
    background-image: url("../assets/images/Screenshot_2.png");
    height: 100%;
    min-height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -2px;

    img {
      max-width: 250px;
    }

    .algoinfo {
      color: #fff;
      position: absolute;
      bottom: 0;
      text-align: center;

      @include media-breakpoint-up(xl) {
        bottom: 70px;
      }

      p {
        font-size: 14px;
        margin-bottom: 2px;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        font-size: 10px;

        li {
          padding: 0 5px;
          border-right: 1px solid #fff;

          &:last-child {
            border-right: none;
          }

          a {
            color: #fff;
          }
        }
      }
    }
  }

  .container-fluid {
    padding: 0;
  }

  .container-fluid,
  .row {
    height: 100%;
  }

  .login-logo {
    top: 20px;
    left: 20px;
    z-index: 1;
    max-width: 60px;

    @include media-breakpoint-up(md) {
      max-width: 80px;
    }

    @include media-breakpoint-up(lg) {
      max-width: 200px;
    }
  }

  .microsoft {
    max-width: 200px;
  }

  .login-microsoft {
    max-height: 30px;

    @media (min-width: 992px) {
      max-height: 100px;
    }
  }

  .top-bar {
    margin-top: 60px;
    margin-bottom: 100px;
    width: 100%;
    z-index: 1;
    padding: 0 15px;
  }

  .box-content {
    z-index: 9;
    padding: 0 25px;
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;

    @include media-breakpoint-up(lg) {
      padding: 0 85px;
    }

    img {
      margin-bottom: 50px;
    }

    .form-group {
      label {
        font-size: 18px;
      }
    }

    h1 {
      color: #fff;
      font-size: 48px;
      margin-bottom: 70px;
      font-weight: 300;
    }

    .mat-form-field {
      margin-bottom: 20px;

      &.mat-form-field-invalid {
        i {
          color: #fe0000;
        }

        .mat-form-field-ripple {
          background-color: #fe0000;
          left: 35px;
          width: 93%;
        }
      }
    }

    .mat-checkbox-checked.mat-accent .mat-checkbox-background,
    .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
      background-color: #009872;
      border: 1px solid;
    }

    .mat-form-field-flex {
      background: none;
      padding: 0 0 4px;
      align-items: center;

      .mat-form-field-infix {
        border-top: 0;
        padding: 0px 0 0 20px;
        font-size: 20px;
      }

      input.mat-input-element {
        line-height: 22px;
      }

      i {
        font-size: 30px;
        color: #000;
      }
    }

    .mat-form-field-appearance-fill .mat-form-field-underline::before {
      background-color: #d81bd1;
      left: 35px;
      width: 93%;
    }

    .check-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px;

      .mat-checkbox-layout {
        margin: 0;
      }

      .mat-checkbox-label {
        font-size: 16px;
        color: #fff;
        font-weight: normal;
        font-weight: 300;
      }

      a {
        color: #f54070;
        font-size: 14px;
        font-weight: bold;
        text-decoration: underline;
        text-transform: uppercase;
      }

      &.bottom {
        position: absolute;
        bottom: 0;

        @include media-breakpoint-up(xl) {
          bottom: 70px;
        }

        a {
          margin: 0;
          padding: 0 15px;
          position: relative;

          &:nth-child(2) {
            padding-right: 0;

            &:before {
              display: none;
            }
          }

          &:before {
            content: "";
            position: absolute;
            right: 0;
            width: 1px;
            height: 18px;
            background-color: #f54070;
          }
        }
      }
    }

    .btn-wrapper {
      margin-top: 30px;

      .btn {
        margin: 0;
        background-color: #ee4073;
        height: 50px;
        max-width: 160px;
        padding: 0;
        width: 100%;
        font-size: 16px;
        border-radius: 20px;
        text-transform: uppercase;
        font-weight: bold;
      }

      .check-wrapper {
        margin: 0;
        padding-left: 50px;
      }
    }

    p {
      margin: 0;
      color: #004f3b;
      font-size: 18px;
    }
  }

  input::placeholder,
  input:-webkit-autofill {
    font-size: 20px !important;
    color: #000 !important;
    font-weight: 300 !important;
    background-color: transparent !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #000 !important;
  }
}

.arcadian-panel {
  .mat-expansion-panel {
    margin-bottom: 20px !important;

    .mat-expansion-panel-header {
      background-color: #eaf7f4;

      &:hover,
      &:focus {
        background-color: #eaf7f4;
      }
    }

    .mat-expansion-panel-body {
      padding: 24px 16px;
    }
  }
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #f7476d !important;
}

//dropdown styling
.mat-form-field-appearance-legacy {
  &.mat-focused {
    .mat-form-field-label {
      color: #232323;
    }
  }

  .mat-form-field-infix {
    padding: 8px 0;
  }

  .mat-form-field-label {
    color: #797979;

    span {
      font-size: 18px;
    }
  }
}

.mat-form-field-appearance-legacy {
  .mat-form-field-underline {
    background-color: #ee4a6f;
    height: 2px;
  }
}

.mat-option {
  font-size: 14px !important;

  &.mat-active,
  &:hover {
    background-color: #f04371 !important;
    color: #fff !important;
  }
}

//expansion panel styling
.mat-list-item {
  font-family: "Lato", sans-serif;
}

.summary-wrapper {
  .ag-theme-material {
    border: 1px solid #e0e0e0;
    background-color: #fff;

    .ag-cell {
      line-height: 28px;
    }

    .ag-header .ag-header-cell {
      margin-left: 0;
    }

    .ag-body-horizontal-scroll {
      border-bottom: 1px solid #e2e2e2;
    }
  }

  .mat-expansion-panel {
    margin-bottom: 10px;

    .mat-expansion-panel-header {
      &.mat-expanded {
        margin-bottom: 20px;
      }
    }
  }

  .grid-head {
    font-size: 20px;
    margin-bottom: 15px;
    text-align: center;
    font-weight: 600;
  }

  .all-show-grid {
    margin: 0;
    margin-bottom: 50px;
  }
}

.chat-wrapper {
  background-color: #ec4173;
  padding: 10px;
  border-radius: 50%;
  z-index: 1;
  height: 55px;
  width: 55px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 20px rgba(63, 63, 63, 0.1);
  position: fixed;
  bottom: 80px;
  right: 60px;

  @include media-breakpoint-down(sm) {
    right: 70px;
  }

  &:hover {
    box-shadow: 0 1pt 12pt rgba(0, 0, 0, 0.15);
    transition: all ease-out 0.2s;
  }

  &.bounce_in {
    .message {
      opacity: 0;
      visibility: hidden;
      margin-left: 0;
      transition: 0.4s;
    }

    .cross {
      opacity: 1;
      visibility: visible;
      transform: rotate(90deg);
      transition: 0.4s;
      margin-right: 35px;
    }
  }

  .message {
    max-height: 35px;
    margin-left: 20px;
  }

  .cross {
    opacity: 0;
    visibility: hidden;
    max-height: 20px;
    transform: rotate(45deg);
  }

  @keyframes bounce_out {
    0% {
      opacity: 1;
      transform: scale(1, 1);
      transform-origin: bottom right;
    }

    100% {
      opacity: 0;
      transform: scale(0, 0);
      transform-origin: bottom right;
    }
  }

  @keyframes bounce_in {
    0% {
      opacity: 0;
      transform: scale(0, 0);
      transform-origin: bottom right;
    }

    50% {
      transform: scale(1.03, 1.03);
      transform-origin: bottom right;
    }

    100% {
      opacity: 1;
      transform: scale(1, 1);
      transform-origin: bottom right;
    }
  }
}

.chat-popup {
  position: fixed;
  bottom: 114px;
  right: 100px;
  width: 330px;
  z-index: 2;
  height: 450px;
  background-color: #fff;
  box-shadow: 0 1pt 12pt rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  display: flex;
  flex-direction: column;

  //justify-content: space-between;
  @include media-breakpoint-down(sm) {
    right: 70px;
    width: 290px;
  }

  &.inactive {
    animation-duration: 800ms;
    animation-name: bounce_out;
    transition-timing-function: ease-out;
    opacity: 0;
    visibility: hidden;
  }

  &.active {
    opacity: 1;
    visibility: visible;
    animation-duration: 800ms;
    animation-name: bounce_in;
    transition-timing-function: ease-in;
  }

  .top-header {
    min-height: 70px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 15px 40px 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    color: #fff;
    background-color: #fbb91c;
    background-image: linear-gradient(90deg, #fbb91c 0%, #ec4173 100%);

    img {
      padding-bottom: 15px;
    }

    h1 {
      font-size: 30px;
      margin-bottom: 10px;
    }

    p {
      color: rgba(255, 255, 255, 0.7);
      display: block;
      line-height: 1.5em;
      overflow-wrap: break-word;
      font-size: 16px;
      margin: 0;
    }
  }

  .chat-history {
    iframe {
      height: 380px;
      width: 100%;
      border: none;
      border-bottom: 1px solid rgb(230, 230, 230);
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }

  .top-bottom {
    border-top: 1px solid rgb(230, 230, 230);

    .mat-form-field {
      padding: 18px 30px 0px;
    }

    .mat-form-field-infix {
      border-top: none;
    }

    .mat-form-field-underline {
      height: inherit;
    }
  }
}

.mat-select-panel::-webkit-scrollbar,
.mat-dialog-container::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.mat-select-panel::-webkit-scrollbar-thumb,
.mat-dialog-container::-webkit-scrollbar-thumb {
  background-color: #323232;
  border-radius: 10px;
}

.mat-select-panel::-webkit-scrollbar-track,
.mat-dialog-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.right-sidenav {
  position: fixed;
  right: 0;
  display: flex;
  top: 0;
  bottom: 50px;
  z-index: 9;

  #rightSidenav {
    display: flex;
    flex-direction: row-reverse;

    .mat-drawer-content {
      background-color: #e5e5e5;
      border-left: 1px solid #a0a0a0;
      width: 50px;
      margin-right: 0 !important;
      color: #fff;
      padding: 70px 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .mat-icon-button {
        margin-bottom: 20px;
        box-shadow: none;
        transition: all ease 0.3s;
        color: #a0a0a0;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          bottom: -6px;
          height: 1px;
          background-color: #a0a0a0;
          left: -5px;
          right: -5px;
        }

        &:focus {
          outline: none;
        }

        &:hover {
          transition: all ease 0.3s;
          background-color: #feac47;
          box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
            0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
        }
      }
    }

    &.hide-sidenav {
      .mat-drawer-content {
        margin-right: -300px !important;
      }
    }

    &.show-sidenav {
      .button-notes {
        background-color: rgba(#feac47, 0.8);
        position: relative;
        transition: all ease 0.3s;

        &:after {
          content: " ";
          position: absolute;
          width: 3px;
          height: 40px;
          background-color: #feac47;
          right: -20px;
          border-radius: 3px 0 0 3px;
        }

        &:hover {
          transform: all ease 0.3s;
          background-color: #feac47;
        }
      }
    }
  }

  .mat-drawer {
    width: 300px !important;
    position: static;
    background-color: #fff !important;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
}

.notes-head {
  box-shadow: none !important;
  border-bottom: 1px solid #f2f2f2;
  border-radius: 0 !important;
  padding: 15px 18px 15px 10px !important;

  .mat-card-header {
    display: flex;
    align-items: center;

    .mat-card-title {
      color: #80868b;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      outline: none;
      margin-bottom: 0;
    }

    .mat-card-subtitle {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      color: #5f6368;
      margin-top: 0 !important;
    }

    .notes-actions {
      display: flex;
      align-items: center;

      .material-icons {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        transition: all ease 0.3s;
        cursor: pointer;

        &:hover {
          transition: all ease 0.3s;
          background-color: #e2f1ff;
          box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
            0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
        }
      }
    }
  }
}

.notes-inner-body {
  padding: 15px 18px 15px 10px;

  .notes-action-btn {
    .mat-raised-button {
      width: 100%;
      padding: 0;
      width: 100%;
      padding: 0;
      box-shadow: none;
      transition: all ease 0.3s;

      &:hover {
        background-color: #f2f2f245;
        transition: all ease 0.3s;
        box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
          0 1px 3px 1px rgba(60, 64, 67, 0.15);

        .icon-add {
          color: #f9ab00;
          background-color: #fef7e0;
          transition: all ease 0.3s;
        }
      }

      .mat-button-wrapper {
        display: flex;
        align-items: center;
      }

      .icon-add {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin: 0 10px;
      }
    }
  }

  .notes-final-view,
  .notes-list {
    .mat-card {
      border: 1px solid #f1f1f1;
      transition: all ease 0.3s;
      box-shadow: none;

      &:hover {
        transition: all ease 0.3s;
        box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
          0 1px 3px 1px rgba(60, 64, 67, 0.15);
      }

      .mat-button {
        padding: 0;
        min-width: inherit;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #9e9e9e14;
        transition: all ease 0.3s;

        &:hover {
          transition: all ease 0.3s;
          box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
            0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
        }
      }
    }

    .action-panel {
      padding: 0;

      .mat-raised-button {
        &:hover {
          background-color: #9e9e9e14;
          transition: all ease 0.3s;
        }
      }
    }
  }

  .notes-list {
    .mat-card {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .mat-card-header {
        color: #9e9e9eb5;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1.5px;
      }

      .mat-card-content {
        margin: 0;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: #5f6368;
      }
    }
  }
}

.mat-drawer-content {
  &[style="margin-left: 240px;"] {
    .toggleBtn:after {
      transform: rotate(180deg);
    }
  }
}

.bot-accordian {
  .action-panel.top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 24px 10px 33px;

    @media (max-width: 767px) {
      padding: 10px 0 10px 0;
    }

    .form-heading {
      padding: 0;
      margin: 0;
      font-size: 14px;
      color: #000;
      font-weight: 600;
    }
  }

  .e-list-parent {
    padding: 20px 0 !important;

    .e-text-content {
      padding: 0;

      .e-list-text {
        .action-panel {
          p {
            font-size: 14px;
            color: #000;
          }
        }
      }
    }
  }

  .mat-expansion-panel-body {
    padding: 0;
    // margin-left: 85px;
    // margin-right: 100px;
    // @media (max-width: 767px){
    //   margin-left: 0;
    //   margin-right: 0;
    // }
  }
}

.embedded-report {
  iframe {
    height: 100vh !important;
  }
}

.menu {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;

  .menu-toggle {
    background-color: rgba($color: #070a1b, $alpha: 0.1);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-left: 20px;
    cursor: pointer;

    .icon-more-vertical {
      font-size: 20px;
    }

    &.menu-show {
      .icon-more-vertical {
        &:before {
          @extend .icomoon-font;
          content: "\e974";
          font-size: 12px;
        }
      }
    }
  }

  .sub-menu {
    display: flex;
    flex-direction: row-reverse;
    border-right: 1px solid #898989;
    padding-right: 10px;
    opacity: 0;
    visibility: hidden;

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }
}

.sub-menu-icon {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(63, 63, 63, 0.3);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;

  img {
    max-width: 20px;
    min-width: 20px;
    cursor: pointer;
  }
}

.anomoly-wrapper {
  padding: 10px 20px;

  .anomoly-dropzone {
    ngx-dropzone {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .table {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    margin-top: 20px;
    margin-bottom: 20px;

    > thead > tr > th {
      border: none;
    }

    input {
      width: 20px;
      height: 20px;
    }
  }
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element {
  background: #232323;
}

.user-logout {
  margin: 0;
  border-radius: 0;
  width: 239px;
  border-top: 1px solid #fff;

  .mat-menu-content {
    background-color: #f04371;
    background-image: linear-gradient(0deg, #f04371 0%, #970999 100%);
    color: #fff;

    .mat-menu-item {
      line-height: 40px;
      height: 40px;
      color: #fff;
    }

    .logout {
      border-top: 1px solid #fff;
      padding: 15px;

      a {
        color: #fff;
        font-size: 16px;
      }
    }
  }
}

.loader-inner {
  position: relative;
  padding-left: 40px;
}

.loader-inner img {
  max-width: 30px;
  position: absolute;
  left: 5px;
  top: 5px;
}

@-webkit-keyframes line-scale-pulse-out {
  0% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }

  50% {
    -webkit-transform: scaley(0.4);
    transform: scaley(0.4);
  }

  100% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
}

@keyframes line-scale-pulse-out {
  0% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }

  50% {
    -webkit-transform: scaley(0.4);
    transform: scaley(0.4);
  }

  100% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
}

.line-scale-pulse-out > div {
  width: 6px;
  height: 38px;
  border-radius: 10px;
  margin: 2px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: inline-block;
  -webkit-animation: line-scale-pulse-out 0.9s -0.6s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85);
  animation: line-scale-pulse-out 0.9s -0.6s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85);
}

.line-scale-pulse-out > div:nth-child(1) {
  background-color: #f93e73;
}

.line-scale-pulse-out > div:nth-child(2) {
  background-color: #f0377a;
}

.line-scale-pulse-out > div:nth-child(3) {
  background-color: #e13d72;
}

.line-scale-pulse-out > div:nth-child(4) {
  background-color: #d02985;
}

.line-scale-pulse-out > div:nth-child(5) {
  background-color: #c3259c;
}

.line-scale-pulse-out > div:nth-child(2),
.line-scale-pulse-out > div:nth-child(4) {
  -webkit-animation-delay: -0.4s !important;
  animation-delay: -0.4s !important;
}

.line-scale-pulse-out > div:nth-child(1),
.line-scale-pulse-out > div:nth-child(5) {
  -webkit-animation-delay: -0.2s !important;
  animation-delay: -0.2s !important;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}

.notification-alert {
  display: flex;
  position: relative;

  .bell {
    cursor: pointer;
    font-size: 30px;
    color: #fff;
  }

  .alert-num {
    position: relative;

    p {
      margin: 0;
      position: absolute;
      background-color: red;
      color: #fff;
      border-radius: 50%;
      height: 16px;
      width: 16px;
      right: -3px;
      top: -5px;
      display: flex;
      align-items: center;
      font-size: 10px;
      justify-content: center;
      line-height: inherit;
      padding-top: 2px;
    }
  }

  .notifications-alerts {
    position: absolute;
    top: 0;
    right: 30px;
    z-index: 9;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    background-color: #fff;
    border-radius: 5px;
    width: 460px;
    height: 560px;
    max-height: 560px;
    animation-duration: 800ms;
    animation-name: bounce_notify;
    transition-timing-function: ease-in;

    @include media-breakpoint-down(lg) {
      overflow-y: auto;
      width: 420px;
      height: 520px;
      max-height: 520px;
    }

    @include media-breakpoint-down(sm) {
      width: 280px;
      height: 500px;
      max-height: 500px;
    }

    .notification-head {
      background-color: #fbb91c;
      background-image: linear-gradient(90deg, #fbb91c 0%, #fc9c1d 100%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 10px;
      border-top-left-radius: 5px;
      position: relative;
      border-top-right-radius: 5px;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }

      &:after {
        content: " ";
        position: absolute;
        height: 1px;
        background-color: #ffc107;

        @include media-breakpoint-down(sm) {
          width: 100%;
          left: 0;
          right: 0;
          top: 50%;
        }

        @include media-breakpoint-up(md) {
          width: 70px;
          left: 50%;
        }

        @include media-breakpoint-up(lg) {
          width: 120px;
          left: 45%;
        }
      }
    }

    .notification-title {
      display: flex;
      align-items: center;

      @include media-breakpoint-down(sm) {
        width: 100%;
        justify-content: space-between;
        margin-bottom: 20px;
      }

      h2 {
        color: #ec4173;
        text-transform: uppercase;
        font-size: 16px;
        padding-right: 10px;
      }
    }

    .btn-primary {
      background-color: #232323;
      color: #fff;
      line-height: 30px;
      padding: 0;
    }

    .btn-light {
      border-radius: 20px;
      padding: 0 10px;
      line-height: 30px;
      color: #ec4173;
      display: flex;
      background-color: #fff;

      .material-icons {
        font-size: 22px;
        padding-right: 10px;
      }
    }

    .all-notify {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 10px;
      padding-bottom: 0;
      color: #ec4173;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.notification-new {
  padding: 10px;

  .notification-info {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #9e9e9e;

    .actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 21px;
      }
    }

    &.notify-read {
      background-color: #efeff5;

      .notify {
        background-color: rgba(#232323, 0.2);
      }

      .notify,
      .check {
        color: #232323;
      }
    }
  }

  .notification-details {
    display: flex;

    .notify {
      font-size: 24px;
      color: #ec4173;
      background-color: rgba(#ec4173, 0.2);
      margin-right: 10px;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }

    p {
      white-space: normal;
      font-size: 14px;
      max-width: 310px;
      color: #ec4173;
      line-height: 20px;
    }

    .notification-time {
      display: flex;
      align-items: center;
      color: #9e9e9e;

      .material-icons {
        font-size: 16px;
        padding-right: 8px;
      }

      p {
        line-height: 0;
        margin-bottom: 0;
        font-size: 12px;
        color: #9e9e9e;
      }
    }
  }
}

marquee {
  background-color: #f9f9f9;

  div {
    width: 1000%;
  }

  p {
    float: left;
    width: auto;
    margin: 0;
    padding-right: 30px;
  }
}

@keyframes bounce_notify {
  0% {
    opacity: 0;
    transform: scale(0, 0);
    transform-origin: top right;
  }

  50% {
    transform: scale(1.03, 1.03);
    transform-origin: top right;
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
    transform-origin: top right;
  }
}

.multiselect-dropdown {
  .dropdown-btn {
    border: none !important;
    border-bottom: 1px solid #ee4a6f !important;
    border-radius: inherit !important;
    padding: 6px 0 !important;

    span {
      font-weight: 500;
      // padding-right: 10px !important;
    }

    .selected-item {
      color: white !important;
      font-weight: lighter;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 80%;
      position: relative;
      margin-right: 0px !important;
      // padding-right: 15px !important;

      a {
        position: absolute;
        right: 2px;
        top: -2px;
        font-size: 14px;
      }
    }

    .dropdown-down {
      border-top: 5px solid rgba(0, 0, 0, .54) !important;
      border-left: 5px solid transparent !important;
      border-right: 5px solid transparent !important;
    }

    .dropdown-up {
      border-bottom: 5px solid rgba(0, 0, 0, .54) !important;
      border-left: 5px solid transparent !important;
      border-right: 5px solid transparent !important;
    }

    .dropdown-multiselect__caret {
      height: 20px!important;
      width: 23px !important;
    }

    .dropdown-multiselect__caret::before {
      border-width: 5px 5px 0 !important;
      border-color: rgba(0, 0, 0, .54) transparent !important;
      top: 10px !important;
      color: rgba(0, 0, 0, .54) !important;
    } 
  }
}

.multiselect-item-checkbox input[type="checkbox"] + div:before {
  color: #ee4a6f !important;
  border: 2px solid #ee4a6f !important;
}

.multiselect-item-checkbox input[type="checkbox"]:checked + div:before {
  background-color: #ee4a6f !important;
}

.pointer {
  cursor: pointer;
}

// .dropdown-multiselect__caret {
//   display: none !important;
// }

.master-item-page {
  .ag-root-wrapper.ag-layout-normal {
    height: calc(50vh) !important;
  }
}

.master-item-page,
.product-title-dialog-form,
.item-dialog-form,
.item-page,
.item-promotion-page,
.item-approval-page,
.promotion-planner-page {
  .mat-select-value {
    color: rgba(0, 0, 0, 0.87);
  }

  .mat-select-value-text {
    color: rgba(0, 0, 0, 0.87);
  }

  .mat-select-arrow {
    color: rgba(0, 0, 0, 0.87);
  }

  .mat-select-disabled .mat-select-arrow {
    color: rgba(0, 0, 0, 0.38);
  }

  .mat-form-field-appearance-fill .mat-form-field-infix {
    padding: 0 0 0.75em 0;
  }
}

.master-item-page,
.promotion-detail-page {
  .ag-theme-material {
    .ag-row-selected {
      background-color: #eee !important;
    }
  }
}

.release-date-filter-range {
  width: 30%;
  padding: 0 10px;
}

.product-filter-clear-btn,
.promotion-filter-clear-btn,
.approval-filter-clear-btn {
  width: 10%;
  margin-bottom: auto;
  margin-left: auto;
}

.product-filter-clear-btn,
.promotion-filter-clear-btn,
.approval-filter-clear-btn {
  button {
    width: 100%;
    border-radius: 0px;
    line-height: 45px;
  }
}

.product-title-link {
  color: $secondary !important;
  text-decoration: underline !important;
}

.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate,
.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate {
  background: #ff4081 !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #f04371 !important;
}

.fb-forecast-accordion {
  padding: 40px 50px 0 !important;
  display: block;

  .mat-expansion-panel-header {
    border-top: 2px solid rgb(216, 26, 209);
    padding: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
    padding-top: 10px;
    padding-right: 10px;
  }

  .mat-expansion-panel-content {
    margin-top: 20px;

    .mat-expansion-panel-body {
      margin: 0;
    }
  }

  &.item-wrapper-panel {
    .mat-expansion-panel-header {
      .mat-expansion-indicator {
        &:after {
          padding: 4px;
          margin-top: -12px;
        }
      }
    }
  }

  &.insight-modal-wrapper {
    padding: 25px 50px 0;
    // .item-wrapper{
    //   max-height: 540px;
    // }
  }

  &.promotion-accordion {
    padding-top: 0;

    .mat-expansion-panel-header {
      padding-top: 30px;

      .item-search-wrapper {
        border: 0;
        padding: 0;
        margin: 0;
        width: 100%;
        padding-right: 40px;

        .form-fields {
          border-right: none;
        }
      }
    }

    .mat-expansion-panel-content {
      margin-top: 40px;

      .items-wrapper-grid {
        padding: 0;
      }
    }
  }

  .promotion-header {
    .mat-expansion-panel-header-title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .mat-slide-toggle-label {
        flex-direction: row-reverse;

        .mat-slide-toggle-content {
          font-size: 16px;
          font-weight: bold;
          padding-right: 30px;
          font-family: "Lato", sans-serif;
        }
      }
    }
  }
}

//item form css
.item-search-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #d71ad1;
  margin-bottom: 40px;
  padding-left: 50px;

  .form-fields {
    border-right: 1px solid #707070;
    padding-right: 20px;
    width: 300px;

    .form-group {
      margin-bottom: 0;
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-wrapper {
        padding-bottom: 0;
      }

      input {
        height: 30px;
      }

      .mat-form-field-underline,
      .mat-form-field-ripple {
        height: 0;
      }

      .mat-form-field-flex {
        background-color: #f4f4f4;
      }

      .mat-form-field-infix {
        padding: 0;
        border-top: 0;
      }

      .mat-form-field-label span {
        font-size: 12px;
        padding-left: 10px;
      }

      .mat-icon {
        transform: rotate(90deg);
        cursor: pointer;
      }
    }
  }

  .item-search-btn {
    display: flex;
    align-items: center;
    height: 60px;

    img {
      max-width: 35px;
      cursor: pointer;
      margin-right: 20px;
    }

    .btn {
      border-radius: 0;
      padding: 10px 50px;
      height: 60px;
      background-color: #f7476d;

      @media (min-width: 1900px) {
        padding: 10px 30px;
      }
    }
  }
}

// created by Asad Ilyas
.message-search-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .form-fields {
    width: 100%;

    .form-group {
      margin-bottom: 0;
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-wrapper {
        padding-bottom: 0;
      }

      input {
        height: 30px;
      }

      .mat-form-field-underline,
      .mat-form-field-ripple {
        height: 0;
      }

      .mat-form-field-flex {
        background-color: #f4f4f4;
      }

      .mat-form-field-infix {
        padding: 0;
        border-top: 0;
      }

      .mat-form-field-label span {
        font-size: 12px;
        padding-left: 10px;
      }

      .mat-icon {
        transform: rotate(90deg);
        cursor: pointer;
      }
    }
  }

  .item-search-btn {
    display: flex;
    align-items: center;
    height: 60px;

    img {
      max-width: 35px;
      cursor: pointer;
      margin-right: 20px;
    }

    .btn {
      border-radius: 0;
      padding: 10px 50px;
      height: 60px;
      background-color: #f7476d;

      @media (min-width: 1900px) {
        padding: 10px 30px;
      }
    }
  }
}

//item form css new
.item-search-wrappers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // border-bottom: 2px solid #d71ad1;
  margin-bottom: 40px;
  padding-left: 50px;

  .form-fields {
    padding-right: 20px;
    width: 300px;

    .form-group {
      margin-bottom: 0;
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-wrapper {
        padding-bottom: 0;
      }

      input {
        height: 30px;
      }

      .mat-form-field-underline,
      .mat-form-field-ripple {
        height: 0;
      }

      .mat-form-field-flex {
        background-color: #f4f4f4;
      }

      .mat-form-field-infix {
        padding: 0;
        border-top: 0;
      }

      .mat-form-field-label span {
        font-size: 12px;
        padding-left: 10px;
      }

      .mat-icon {
        transform: rotate(90deg);
        cursor: pointer;
      }
    }
  }

  .item-search-btn {
    display: flex;
    align-items: center;
    height: 60px;

    img {
      max-width: 35px;
      cursor: pointer;
      margin-right: 20px;
    }

    .btn {
      border-radius: 0;
      padding: 10px 50px;
      height: 60px;
      background-color: #f7476d;

      @media (min-width: 1900px) {
        padding: 10px 30px;
      }
    }
  }
}

.account-btn-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .info {
    width: 250px;
  }

  .btn-wrapper {
    margin: 0 !important;
  }
}

.items-wrapper-grid {
  padding: 0 50px;

  .ag-theme-material {
    .ag-header {
      background-color: #8d009d;
      border-radius: 15px 15px 0 0;

      .ag-header-cell {
        background-color: #8d009d;
        padding: 0;

        &:hover {
          background-color: transparent !important;
        }

        .ag-header-icon {
          opacity: 1 !important;
        }

        .ag-icon {
          color: #fff;
        }

        .ag-cell-label-container {
          border-right: 1px solid #fff;
          padding: 0 14px;
        }
      }

      .ag-header-cell-label .ag-header-cell-text {
        color: #fff;
        font-weight: 500;
        font-size: 12px;
      }
    }

    .ag-header-viewport {
      border-bottom: none;
    }

    .ag-paging-panel {
      background-color: #f6f6f6;

      .ag-paging-row-summary-panel,
      .ag-paging-page-summary-panel {
        background-color: #f6f6f6;
      }

      .ag-paging-button {
        .ag-icon {
          color: #000;
          opacity: 1;
        }
      }
    }

    .ag-center-cols-viewport .ag-row .ag-cell {
      font-size: 12px;
      font-weight: bold;
      display: flex;
      align-items: center;
    }

    app-add-button-renderer {
      width: 100%;
    }

    app-button-renderer {
      .mat-slide-toggle-label {
        flex-direction: row-reverse;

        .mat-slide-toggle-bar {
          margin-left: 12px;
        }

        .mat-slide-toggle-content {
          font-weight: 500;
        }
      }
    }
  }
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #f04371 !important;
}

.date-range-panel {
  margin-top: 20px;

  .mat-option {
    background-color: rgba(0, 0, 0, 0.04) !important;
    height: 175px !important;
    color: rgba(0, 0, 0, 0.87) !important;
    font-weight: 600;
  }

  .mat-form-field {
    display: block;
  }
}

.ag-column-checkbox-section {
  .mat-checkbox {
    width: 100%;
  }

  .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
    .mat-ripple-element,
  .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
    .mat-ripple-element {
    background: #ff4081;
  }

  .mat-checkbox-layout {
    width: 100%;
  }

  .mat-checkbox-label-before .mat-checkbox-inner-container {
    margin-left: auto;
    margin-right: 20px;
  }
}

.search-action-icon {
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  border-radius: 15px;
  font-size: 22px !important;
}

.search-action-icon:hover {
  box-shadow: 0px 2px 4px rgba(63, 63, 63, 0.3);
}

.import-export-button {
  margin-left: 10px !important;
}

.inpage-expansion-panel {
  .mat-expansion-panel {
    margin-top: 10px !important;
  }

  .mat-expansion-panel-header {
    background-color: #fff !important;
    padding: 0 40px 0 20px;
  }

  .mat-expansion-panel-header-title {
    margin: auto;
  }

  .panel-header-action-button {
    margin-left: auto;
  }

  .btn-show {
    .mat-slide-toggle {
      margin-right: 10px;
    }

    button {
      margin-right: 10px;
    }
  }
}

.theme-hr {
  border-top: 1px solid rgba(194, 110, 194, 1);
  box-shadow: 0 0px 1px 0 rgba(152, 10, 153, 68%);
}

.hidden {
  visibility: hidden;
  width: 1px;
  height: 1px;
}

.forecast-grid {
  .ag-column-group-class,
  .ag-column-group-first-header {
    border-left: 2px solid lightgray;
  }

  .ag-header-cell {
    padding-left: 10px;
    padding-right: 10px;
  }

  .ag-ltr .ag-cell {
    border-right: none;
    margin: -1px;
  }

  .ag-center-cols-clipper {
    margin-left: -1px;
  }

  .ag-center-cols-viewport {
    border-left: 2px solid darkgray;
  }

  .ag-cell {
    padding-left: 10px;
    padding-right: 10px;
  }

  .ag-group-box-cell {
    .ag-group-expanded {
      margin-right: 10px;
    }

    .ag-row-group-leaf-indent {
      margin-left: 10px;
    }
  }

  .ag-header-group-cell {
    background-color: #f2f2f2;
  }

  .ag-header-group-cell-label {
    justify-content: center;
  }
}

.item-approval-page {
  .mat-tab-body-content {
    overflow-x: hidden;
  }
}

.item-wrapper {
  .mat-tab-body {
    margin-top: 25px;
  }

  .img-fluid {
    width: 100%;
  }

  ul {
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 0;

    li {
      a {
        color: #6d6d6d;
        text-decoration: underline;
      }
    }
  }
}

// asad add grid css
.items-wrapper-grids-change {
  padding: 0 50px;

  .ag-theme-material {
    .ag-header {
      background-color: #8d009d;
      border-radius: 15px 15px 0 0;

      .ag-header-cell {
        background-color: #8d009d;
        padding: 0;

        .ag-checkbox-input-wrapper {
          font-family: "agGridMaterial";
          font-size: 18px;
          line-height: 18px;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          width: 18px;
          height: 18px;
          background-color: var(--ag-checkbox-background-color);
          border-radius: 2px;
          display: inline-block;
          vertical-align: middle;
          flex: none;
          background-color: white;
        }

        &:hover {
          background-color: transparent !important;
        }

        .ag-header-icon {
          opacity: 1 !important;
        }

        .ag-icon {
          color: #fff;
        }

        .ag-cell-label-container {
          border-right: 1px solid #fff;
          padding: 0 14px;
        }
      }

      .ag-header-cell-label .ag-header-cell-text {
        color: #fff;
        font-weight: 500;
        font-size: 12px;
      }
    }

    .ag-header-viewport {
      border-bottom: none;
    }

    .ag-paging-panel {
      background-color: #f6f6f6;

      .ag-paging-row-summary-panel,
      .ag-paging-page-summary-panel {
        background-color: #f6f6f6;
      }

      .ag-paging-button {
        .ag-icon {
          color: #000;
          opacity: 1;
        }
      }
    }

    .ag-center-cols-viewport .ag-row .ag-cell {
      font-size: 12px;
      font-weight: bold;
      display: flex;
      align-items: center;
    }

    app-add-button-renderer {
      width: 100%;
    }

    app-button-renderer {
      .mat-slide-toggle-label {
        flex-direction: row-reverse;

        .mat-slide-toggle-bar {
          margin-left: 12px;
        }

        .mat-slide-toggle-content {
          font-weight: 500;
        }
      }
    }
  }
}

#fb-forecast {
  .item-wrapper {
    .padding-right {
      @media (min-width: 1600px) {
        padding-right: 40px;
      }
    }

    .mat-tab-body {
      margin-top: 25px;
    }

    .img-fluid {
      width: 100%;
    }

    ul {
      padding: 0;
      list-style-type: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 0;

      li {
        a {
          color: #6d6d6d;
          text-decoration: underline;
        }
      }
    }

    .info {
      margin-top: 40px;
      border: 1px solid #707070;
      padding: 10px;

      .labels {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        &:nth-child(4) {
          margin-bottom: 0;
        }

        h4 {
          margin: 0;
          color: #adacad;
          font-size: 12px;
        }
      }
    }

    .search-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 20px;
      margin-top: 40px;

      .form-group {
        margin: 0;

        input {
          width: 250px;

          &::placeholder {
            font-weight: normal;
          }
        }

        i {
          color: #000;
          font-weight: bold;
          font-size: 16px;
          transform: rotate(95deg);
          cursor: pointer;
        }
      }

      .icon-wrapper {
        i {
          font-size: 24px;
          margin-left: 5px;
          cursor: pointer;
        }
      }
    }

    .form-group {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 10px;

      label {
        color: #302e31;
        font-size: 12px;
        line-height: 19px;
        min-width: 130px;
        margin: 0;
        width: 180px;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      input {
        width: 100%;
        border: none;
        height: 30px;
        padding: 0 10px;
        text-align: left;
        background-color: #f4f4f4;

        &:focus {
          outline: none;
        }
      }

      i {
        position: absolute;
        right: 5px;
        color: #393939;
        font-size: 10px;
        cursor: pointer;
      }

      .mat-select {
        border: none;
        background-color: #f4f4f4;
        height: 30px;
        padding: 0 5px 0 10px;
        font-size: 12px;
        border-radius: 0;
        width: 100%;
        text-align: left;

        .mat-select-trigger {
          padding-top: 2px;
        }

        .mat-select-arrow {
          border-style: solid;
          border-width: 0 2px 2px 0;
          content: "";
          display: inline-block;
          padding: 3px;
          color: #000 !important;
          transform: rotate(45deg);
          width: inherit;
          height: inherit;
          margin: 0;
          border-left: unset;
          border-right: 2px solid;
        }

        .mat-select-placeholder {
          color: #302e31;

          @media (min-width: 1600px) {
            font-size: 12px;
          }
        }
      }

      .btn {
        height: 25px;
        border-radius: 0;
        font-size: 12px;
        padding: 0;
        min-width: 65px;
        justify-content: center;
        border: none;
      }

      .mat-form-field-appearance-legacy {
        .mat-form-field-wrapper {
          padding-bottom: 0;
        }

        .mat-form-field-prefix,
        .mat-form-field-suffix {
          margin: auto;
        }

        .mat-datepicker-toggle {
          color: #000;
        }

        .mat-form-field-underline,
        .mat-form-field-ripple {
          height: 0 !important;
        }

        .mat-form-field-flex {
          background-color: #f4f4f4;
        }

        .mat-form-field-infix {
          padding: 0;
          border-top: 0;
        }

        .mat-form-field-label span {
          font-size: 12px;
          padding-left: 10px;
        }
      }

      .user-data {
        background-color: #f4f4f4;
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        padding: 0 10px;
      }

      &.time-dropdown {
        app-treeview-dropdown {
          width: 100%;

          .dropdown {
            .dropdown-toggle:before {
              right: 7px;
              top: 9px;
              padding: 3px;
            }

            .btn {
              background-color: #f4f4f4;
              height: 30px !important;
              padding: 0 5px 0 10px;
              font-size: 12px;
              border-radius: 0;
              width: 100%;
              text-align: left;
              box-shadow: none;
            }
          }
        }
      }

      // asad css replace
      &.time-dropdown {
        microsoft-treeview-dropdown {
          width: 100%;

          .dropdown {
            .dropdown-toggle:before {
              right: 7px;
              top: 9px;
              padding: 3px;
            }

            .btn {
              background-color: #f4f4f4;
              height: 30px !important;
              padding: 0 5px 0 10px;
              font-size: 12px;
              border-radius: 0;
              width: 100%;
              text-align: left;
              box-shadow: none;
            }
          }
        }
      }

      &.scrolling-dropdown {
        margin: 0 !important;
      }
    }

    .btn-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 20px;
      margin-bottom: 30px;

      .btn {
        border-radius: 0;
        width: auto;
        height: 50px;
        font-size: 16px;
        margin-left: 15px;

        &.btn-secondary {
          background-color: transparent;
          color: #f7476d;
          border: 2px solid #f7476d;
          padding: 0 50px;
        }

        &.btn-primary {
          background-color: #f7476d;
          justify-content: center;
          padding: 0 50px;
        }
      }
    }

    .mat-tab-header {
      border-bottom: 1px solid #707070;
      overflow: visible;

      .mat-tab-label-container {
        overflow: visible;
      }

      .mat-tab-label {
        border-right: 1px solid #707070;
        height: 40px;
        opacity: 1;
        min-width: 180px;
        color: rgba($color: #302e31, $alpha: 0.4);

        &.mat-tab-label-active {
          color: #302e31;
        }
      }
    }

    .mat-tab-group.mat-primary .mat-ink-bar {
      background-color: #fff;
    }

    .mat-tab-group .mat-ink-bar {
      bottom: -2px;
    }

    .select-arrow {
      .mat-select {
        .mat-select-arrow {
          border: none;
        }
      }
    }

    .users-wrapper {
      border-top: 1px solid #707070;
      margin-top: 10px;
      padding-top: 10px;
    }

    &.insight-wrapper {
      .item-search-wrapper {
        margin-bottom: 0;

        .form-fields {
          padding-right: 10px;
          width: 225px;
        }
      }

      .insight-form {
        display: flex;
        padding-left: 10px;
        flex-wrap: wrap;
        margin-right: auto;

        .form-group {
          margin-bottom: 0;
          margin-right: 10px;
          margin-top: 5px;

          label {
            min-width: 60px;
          }

          .mat-select {
            min-width: 100px;
          }

          .mat-form-field {
            width: 100px !important;
          }
        }
      }

      .fb-forecast-accordion {
        padding-top: 0;

        .mat-expansion-panel-header {
          border-top: 0;
        }

        .mat-expansion-panel-header-description {
          justify-content: flex-end;

          a {
            color: #000;
            font-size: 14px;

            &:first-child {
              padding-right: 5px;
              border-right: 1px solid #000;
              margin-right: 5px;
            }
          }
        }

        .mat-expansion-panel-body {
          #item-manager {
            padding: 0;
          }

          .items-wrapper-grid {
            padding: 0;
          }
        }
      }

      .insight-gallery-banner {
        background-color: #8d009d;
        border-radius: 15px 15px 0 0;
        padding: 10px 20px;

        p {
          margin: 0;
          color: #fff;
        }
      }

      .insight-gallery {
        border-bottom: 1px solid gray;
        padding: 30px;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          right: 0;
          top: 25px;
          bottom: 25px;
          width: 1px;
          background-color: gray;
        }

        .insight-gallery-inner {
          text-align: left;
          padding-top: 30px;

          p {
            margin-bottom: 0;
            color: #000;
          }

          a {
            color: #8d009d;
            text-decoration: underline;
          }

          .gallery-date-value {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #000;

            p {
              margin-bottom: 0;

              span {
                font-size: 18px;
                font-weight: 500;
              }
            }
          }
        }
      }
    }

    .insight-form-wrapper {
      .col-md-3 {
        ul {
          margin-bottom: 20px;
        }
      }

      .col-md-9 {
        .row {
          border-top: 1px solid gray;
          padding-top: 20px;
          margin-top: 10px;

          &:nth-child(2) {
            .form-group {
              align-items: flex-start;
            }
          }

          &.row-inner {
            padding: 0;
            margin: 0;
            border: none;
            width: 100%;
          }
        }

        .form-group {
          .mat-form-field {
            .mat-form-field-infix {
              display: flex;

              label {
                background-color: #fff;
              }
            }
          }
        }
      }
    }

    &.fb-forecast-planner {
      margin-top: 10px;

      .col-md-8 {
        @media (min-width: 1600px) {
          padding-right: 30px;
        }
      }

      .col-md-4 {
        @media (min-width: 1600px) {
          &:nth-child(1) {
            padding-right: 30px;
          }

          &:nth-child(2) {
            padding-left: 30px;
            padding-right: 30px;
          }

          &:nth-child(3) {
            padding-left: 30px;
          }
        }
      }

      .fb-line {
        border-right: 1px solid #707070;
      }

      .method-planner-top {
        border-bottom: 1px solid #707070;
        margin-bottom: 38px;

        .fb-line {
          padding-bottom: 40px;

          @media (min-width: 1600px) {
            padding-right: 30px;
          }
        }

        .fb-line2 {
          @media (min-width: 1600px) {
            padding-left: 30px;
          }
        }

        .form-group {
          label {
            min-width: 130px;

            @media (min-width: 1600px) {
              min-width: 225px;
            }
          }
        }
      }

      .method-planner-bottom {
        .form-group {
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 30px;

          label {
            margin-bottom: 10px;
          }
        }

        .align {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }

      h3 {
        color: #302e31;
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        margin-bottom: 25px;
        font-family: "Lato", sans-serif;

        // @media (min-width: 1600px) {
        //   font-size: 20px;
        // }
      }

      .form-group {
        label {
          font-size: 14px;
          font-family: "Lato", sans-serif;
          font-weight: 500;
          min-width: 90px;

          @media (min-width: 1600px) {
            min-width: 130px;
          }
        }

        input {
          width: 100% !important;
          border: none !important;
          height: 30px !important;
          padding: 0 10px !important;
          text-align: left !important;
          background-color: #f4f4f4 !important;
        }

        input::placeholder {
          font-weight: normal !important;

          @media (min-width: 1600px) {
            color: #302e31;
            font-size: 16px;
          }
        }

        .mat-select {
          .mat-select-value-text {
            font-weight: normal !important;

            @media (min-width: 1600px) {
              font-size: 12px;
            }
          }
        }
      }

      app-treeview-dropdown {
        width: 100%;

        .dropdown {
          .btn {
            background-color: #f4f4f4 !important;
            box-shadow: none !important;
            height: 30px !important;
            padding: 0 10px !important;

            &:hover {
              color: #000 !important;
            }
          }

          .dropdown-toggle {
            &[aria-expanded="true"] {
              color: #000 !important;

              &:before {
                top: 15px !important;
              }
            }

            &:before {
              right: 10px !important;
              top: 7px !important;
              padding: 4px !important;
            }
          }
        }
      }

      // asad implement css
      microsoft-treeview-dropdown {
        width: 100%;

        .dropdown {
          .btn {
            background-color: #f4f4f4 !important;
            box-shadow: none !important;
            height: 30px !important;
            padding: 0 10px !important;

            &:hover {
              color: #000 !important;
            }
          }

          .dropdown-toggle {
            &[aria-expanded="true"] {
              color: #000 !important;

              &:before {
                top: 15px !important;
              }
            }

            &:before {
              right: 10px !important;
              top: 7px !important;
              padding: 4px !important;
            }
          }
        }
      }

      .btn-wrapper {
        margin: 0;
        justify-content: flex-start;

        .btn.btn-primary {
          border-radius: 12px;
          font-weight: bold;
          font-family: "Lato", sans-serif;
          height: 45px;
          padding: 0 69px;
          line-height: 19px;
        }
      }
    }

    &.promotion-wrapper {
      .col-md-4 {
        padding-left: 10px;
      }

      .form-group {
        label {
          font-size: 14px;

          @media (min-width: 1600px) {
            font-size: 16px;
          }

          &.promotion-label {
            @media (min-width: 1600px) {
              min-width: 180px;
            }
          }

          &.promotion-label2 {
            @media (min-width: 1600px) {
              min-width: 220px;
            }
          }
        }
      }

      .btn-wrapper {
        margin-top: 14px;
        margin-bottom: 40px;
        width: 100%;

        .btn {
          &.btn-primary {
            height: 55px;
            border-radius: 0;
            font-size: 18px;
            padding: 0;
            min-width: 130px;
            width: 130px;
          }
        }
      }
    }
  }
}

.item-wrapper {
  .padding-right {
    @media (min-width: 1600px) {
      padding-right: 40px;
    }
  }

  .mat-tab-body {
    margin-top: 25px;
  }

  .img-fluid {
    width: 100%;
  }

  ul {
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 0;

    li {
      a {
        color: #6d6d6d;
        text-decoration: underline;
      }
    }
  }

  .info {
    margin-top: 40px;
    border: 1px solid #707070;
    padding: 10px;

    .labels {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      &:nth-child(4) {
        margin-bottom: 0;
      }

      h4 {
        margin: 0;
        color: #adacad;
        font-size: 12px;
      }
    }
  }

  .search-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 40px;

    .form-group {
      margin: 0;

      input {
        width: 250px;

        &::placeholder {
          font-weight: normal;
        }
      }

      i {
        color: #000;
        font-weight: bold;
        font-size: 16px;
        transform: rotate(95deg);
        cursor: pointer;
      }
    }

    .icon-wrapper {
      i {
        font-size: 24px;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }

  .form-group {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;

    label {
      color: #302e31;
      font-size: 12px;
      line-height: 19px;
      min-width: 130px;
      margin: 0;
      width: 180px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    input {
      width: 100%;
      border: none;
      height: 30px;
      padding: 0 10px;
      text-align: left;
      background-color: #f4f4f4;

      &:focus {
        outline: none;
      }
    }

    i {
      position: absolute;
      right: 5px;
      color: #393939;
      font-size: 10px;
      cursor: pointer;
    }

    .mat-select {
      border: none;
      background-color: #f4f4f4;
      height: 30px;
      padding: 0 10px 0 10px;
      font-size: 12px;
      border-radius: 0;
      width: 100%;
      text-align: left;

      .mat-select-trigger {
        padding-top: 2px;

        .mat-select-value {
          padding-top: 4px;
        }
      }

      .mat-select-arrow {
        border-style: solid;
        border-width: 0 2px 2px 0;
        content: "";
        display: inline-block;
        padding: 4px;
        color: #000 !important;
        transform: rotate(45deg);
        width: inherit;
        height: inherit;
        margin: 0;
        border-left: unset;
        border-right: 2px solid;
      }

      .mat-select-placeholder {
        color: #302e31;

        @media (min-width: 1600px) {
          font-size: 12px;
        }
      }
    }

    .btn {
      height: 25px;
      border-radius: 0;
      font-size: 12px;
      padding: 0;
      min-width: 65px;
      justify-content: center;
      border: none;
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-wrapper {
        padding-bottom: 0;
      }

      .mat-form-field-prefix,
      .mat-form-field-suffix {
        margin: auto;
      }

      .mat-datepicker-toggle {
        color: #000;
      }

      .mat-form-field-underline,
      .mat-form-field-ripple {
        height: 0 !important;
      }

      .mat-form-field-flex {
        background-color: #f4f4f4;
      }

      .mat-form-field-infix {
        padding: 0;
        border-top: 0;
      }

      .mat-form-field-label span {
        font-size: 12px;
        padding-left: 10px;
      }
    }

    .user-data {
      background-color: #f4f4f4;
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      padding: 0 10px;
    }

    &.time-dropdown {
      app-treeview-dropdown {
        width: 100%;

        .mat-select-trigger {
          padding-top: 2px;

          .mat-select-value {
            padding-top: 4px;
          }
        }

        .dropdown {
          .dropdown-toggle:before {
            right: 7px;
            top: 9px;
            padding: 3px;
          }

          .btn {
            background-color: #f4f4f4;
            height: 30px !important;
            padding: 0 5px 0 10px;
            font-size: 12px;
            border-radius: 0;
            width: 100%;
            text-align: left;
            box-shadow: none;
          }
        }
      }
    }

    // Asad implement csss
    &.time-dropdown {
      microsoft-treeview-dropdown {
        width: 100%;

        .dropdown {
          .dropdown-toggle:before {
            right: 7px;
            top: 9px;
            padding: 3px;
          }

          .btn {
            background-color: #f4f4f4;
            height: 30px !important;
            padding: 0 5px 0 10px;
            font-size: 12px;
            border-radius: 0;
            width: 100%;
            text-align: left;
            box-shadow: none;
          }
        }
      }
    }

    &.scrolling-dropdown {
      margin: 0 !important;
    }
  }

  .btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    margin-bottom: 30px;

    .btn {
      border-radius: 0;
      width: auto;
      height: 50px;
      font-size: 16px;
      margin-left: 15px;

      &.btn-secondary {
        background-color: transparent;
        color: #f7476d;
        border: 2px solid #f7476d;
        padding: 0 50px;
      }

      &.btn-primary {
        background-color: #f7476d;
        justify-content: center;
        padding: 0 50px;
      }
    }
  }

  .mat-tab-header {
    border-bottom: 1px solid #707070;
    overflow: visible;

    .mat-tab-label-container {
      overflow: visible;
    }

    .mat-tab-label {
      border-right: 1px solid #707070;
      height: 40px;
      opacity: 1;
      min-width: 180px;
      color: rgba($color: #302e31, $alpha: 0.4);

      &.mat-tab-label-active {
        color: #302e31;
      }
    }
  }

  .mat-tab-group.mat-primary .mat-ink-bar {
    background-color: #fff;
  }

  .mat-tab-group .mat-ink-bar {
    bottom: -2px;
  }

  .select-arrow {
    .mat-select {
      .mat-select-arrow {
        border: none;
      }
    }
  }

  .users-wrapper {
    border-top: 1px solid #707070;
    margin-top: 10px;
    padding-top: 10px;
  }

  &.insight-wrapper {
    .item-search-wrapper {
      margin-bottom: 0;

      .form-fields {
        padding-right: 10px;
        width: 225px;
      }
    }

    .insight-form {
      display: flex;
      padding-left: 10px;
      flex-wrap: wrap;
      margin-right: auto;

      .form-group {
        margin-bottom: 0;
        margin-right: 10px;
        margin-top: 5px;

        label {
          min-width: 60px;
        }

        .mat-select {
          min-width: 100px;
        }

        .mat-form-field {
          width: 100px !important;
        }
      }
    }

    .fb-forecast-accordion {
      padding-top: 0;

      .mat-expansion-panel-header {
        border-top: 0;
      }

      .mat-expansion-panel-header-description {
        justify-content: flex-end;

        a {
          color: #000;
          font-size: 14px;

          &:first-child {
            padding-right: 5px;
            border-right: 1px solid #000;
            margin-right: 5px;
          }
        }
      }

      .mat-expansion-panel-body {
        #item-manager {
          padding: 0;
        }

        .items-wrapper-grid {
          padding: 0;
        }
      }
    }

    .insight-gallery-banner {
      background-color: #8d009d;
      border-radius: 15px 15px 0 0;
      padding: 10px 20px;

      p {
        margin: 0;
        color: #fff;
      }
    }

    .insight-gallery {
      border-bottom: 1px solid gray;
      padding: 30px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        right: 0;
        top: 25px;
        bottom: 25px;
        width: 1px;
        background-color: gray;
      }

      .insight-gallery-inner {
        text-align: left;
        padding-top: 30px;

        p {
          margin-bottom: 0;
          color: #000;
        }

        a {
          color: #8d009d;
          text-decoration: underline;
        }

        .gallery-date-value {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #000;

          p {
            margin-bottom: 0;

            span {
              font-size: 18px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  .insight-form-wrapper {
    .col-md-3 {
      ul {
        margin-bottom: 20px;
      }
    }

    .col-md-9 {
      .row {
        border-top: 1px solid gray;
        padding-top: 20px;
        margin-top: 10px;

        &:nth-child(2) {
          .form-group {
            align-items: flex-start;
          }
        }

        &.row-inner {
          padding: 0;
          margin: 0;
          border: none;
          width: 100%;
        }
      }

      .form-group {
        .mat-form-field {
          .mat-form-field-infix {
            display: flex;

            label {
              background-color: #fff;
            }
          }
        }
      }
    }
  }

  &.fb-forecast-planner {
    margin-top: 10px;

    .col-md-8 {
      @media (min-width: 1600px) {
        padding-right: 30px;
      }
    }

    .col-md-4 {
      @media (min-width: 1600px) {
        &:nth-child(1) {
          padding-right: 30px;
        }

        &:nth-child(2) {
          padding-left: 30px;
          padding-right: 30px;
        }

        &:nth-child(3) {
          padding-left: 30px;
        }
      }
    }

    .fb-line {
      border-right: 1px solid #707070;
    }

    .method-planner-top {
      border-bottom: 1px solid #707070;
      margin-bottom: 38px;

      .fb-line {
        padding-bottom: 40px;

        @media (min-width: 1600px) {
          padding-right: 30px;
        }
      }

      .fb-line2 {
        @media (min-width: 1600px) {
          padding-left: 30px;
        }
      }

      .form-group {
        label {
          min-width: 130px;

          @media (min-width: 1600px) {
            min-width: 225px;
          }
        }
      }
    }

    .method-planner-bottom {
      .form-group {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 30px;

        label {
          margin-bottom: 10px;
        }
      }

      .align {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    h3 {
      color: #302e31;
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      margin-bottom: 25px;
      font-family: "Lato", sans-serif;

      // @media (min-width: 1600px) {
      //   font-size: 20px;
      // }
    }

    .form-group {
      label {
        font-size: 14px;
        font-family: "Lato", sans-serif;
        font-weight: 500;
        min-width: 90px;

        @media (min-width: 1600px) {
          min-width: 130px;
        }
      }

      input {
        width: 100% !important;
        border: none !important;
        height: 30px !important;
        padding: 0 10px !important;
        text-align: left !important;
        background-color: #f4f4f4 !important;
      }

      input::placeholder {
        font-weight: normal !important;

        @media (min-width: 1600px) {
          color: #302e31;
          font-size: 16px;
        }
      }

      .mat-select {
        .mat-select-value-text {
          font-weight: normal !important;

          @media (min-width: 1600px) {
            font-size: 12px;
          }
        }
      }
    }

    app-treeview-dropdown {
      width: 100%;

      .dropdown {
        .btn {
          background-color: #f4f4f4 !important;
          box-shadow: none !important;
          height: 30px !important;
          padding: 0 10px !important;

          &:hover {
            color: #000 !important;
          }
        }

        .dropdown-toggle {
          &[aria-expanded="true"] {
            color: #000 !important;

            &:before {
              top: 15px !important;
            }
          }

          &:before {
            right: 10px !important;
            top: 7px !important;
            padding: 4px !important;
          }
        }
      }
    }

    // asad implement css
    microsoft-treeview-dropdown {
      width: 100%;

      .dropdown {
        .btn {
          background-color: #f4f4f4 !important;
          box-shadow: none !important;
          height: 30px !important;
          padding: 0 10px !important;

          &:hover {
            color: #000 !important;
          }
        }

        .dropdown-toggle {
          &[aria-expanded="true"] {
            color: #000 !important;

            &:before {
              top: 15px !important;
            }
          }

          &:before {
            right: 10px !important;
            top: 7px !important;
            padding: 4px !important;
          }
        }
      }
    }

    .btn-wrapper {
      margin: 0;
      justify-content: flex-start;

      .btn.btn-primary {
        border-radius: 12px;
        font-weight: bold;
        font-family: "Lato", sans-serif;
        height: 45px;
        padding: 0 69px;
        line-height: 19px;
      }
    }
  }

  &.promotion-wrapper {
    .col-md-4 {
      padding-left: 10px;
    }

    .form-group {
      label {
        font-size: 14px;

        @media (min-width: 1600px) {
          font-size: 16px;
        }

        &.promotion-label {
          @media (min-width: 1600px) {
            min-width: 180px;
          }
        }

        &.promotion-label2 {
          @media (min-width: 1600px) {
            min-width: 220px;
          }
        }
      }
    }

    .btn-wrapper {
      margin-top: 14px;
      margin-bottom: 40px;
      width: 100%;

      .btn {
        &.btn-primary {
          height: 55px;
          border-radius: 0;
          font-size: 18px;
          padding: 0;
          min-width: 130px;
          width: 130px;
        }
      }
    }
  }
}

#fb-forecast-new-planner {
  min-height: 90vh;
  padding: 0;

  .side-label {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 5px;
  }

  .dropdown {
    .btn {
      border-radius: 0;
      color: #302e31;
      font-weight: normal;
      height: 48px;
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      border: none;
      margin: 0;
      padding: 0 20px 0 35px;
      background-color: #fff;

      @media (min-width: 1600px) {
        font-size: 16px;
      }

      &:hover {
        background-color: #f7476d;
        color: #fff;
      }
    }

    .dropdown-toggle {
      &[aria-expanded="true"] {
        color: #fff;
        &:before {
          top: 20px;
          transform: rotate(-135deg);
        }
      }

      &:after {
        display: none;
      }

      &:before {
        position: absolute;
        right: 20px;
        top: 15px;
        border-style: solid;
        border-width: 0 2px 2px 0;
        content: "";
        display: inline-block;
        padding: 5px;
        transform: rotate(45deg);
      }
    }

    .dropdown-menu {
      width: 100%;
      margin: 0;
      border-radius: 0;
      padding: 0;

      .dropdown-container {
        padding: 0;
        zoom: 80%;

        .treeview-header {
          .dropdown-divider {
            border-top: 1px solid #a3a3a3;
          }
        }
      }

      .row-all {
        .col-12 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 20px 0 40px;

          .float-right {
            i {
              position: static;
              font-size: 14px;
              right: 0;
            }
          }
        }
      }
    }

    .treeview-container {
      padding: 0;

      .row-item {
        margin-bottom: 0;
        padding: 16px 45px;
        position: relative;
        width: 100%;

        &:before {
          content: "";
          position: absolute;
          bottom: 0;
          border-bottom: 1px solid #a3a3a3;
          left: -100px;
          right: 0;
        }

        i {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);

          &:before {
            border-style: solid;
            border-width: 0 2px 2px 0;
            content: "";
            display: inline-block;
            padding: 5px;
            transform: rotate(45deg);
          }

          .bi {
            display: none;
          }
        }
      }

      .treeview-item {
        .treeview-item {
          margin-left: 0;
          padding: 0 0 0 25px;
        }
      }
    }
  }

  .form-check-input {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it

    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
      display: block;
      margin: 0;
      width: 100%;
    }

    // Box.
    & + label:before {
      content: "";
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 22px;
      height: 22px;
      background: white;
      border: 1px solid #000;
    }

    // Box checked
    &:disabled + label:before {
      background: #c2c1c1 !important;
      border: none !important;
    }

    &:checked + label:before {
      background: #f7476d;
      border: none;
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:after {
      content: "";
      position: absolute;
      left: 5px;
      top: 11px;
      background: white;
      width: 3px;
      height: 3px;
      box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white,
        4px -5px 0 white, 4px -9px 0 white, 4px -8px 0 white;
      transform: rotate(45deg);
    }
  }

  div.settings label {
    text-align: right;
  }

  .shipmentGroup {
    background-color: lightgray !important;
  }

  // tr {
  //   border-bottom: 2px solid lightgray;
  // }

  .ag-theme-material.budget-grid
    .ag-header
    .ag-header-cell-label
    .ag-header-cell-text {
    overflow: visible;
    text-overflow: inherit;
    white-space: normal;
  }

  .multiselect-dropdown {
    margin-top: 10px !important;
  }

  .rag-green-outer {
    background: #bfc9ca !important;
    /* border: 2px solid !important; */
    border-radius: 2px;
  }

  .rag-grey-outer {
    background: lightgray;
    border-left: 2px solid !important;
  }

  .rag-white-outer {
    background: #fff;
    border-left: 2px solid !important;
  }

  .rag-border-outer {
    border-bottom: 2px solid !important;
  }

  .rag-border-top-outer {
    border-top: 2px solid !important;
  }

  .multiselect-item-checkbox {
    white-space: nowrap !important;
  }

  .selected-item {
    white-space: nowrap !important;
    max-width: 100% !important;
  }

  ::ng-deep .multiselect-dropdown .dropdown-btn {
    display: inline-block;
    border: 1px solid #adadad;
    width: 100%;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.1;
    text-align: left;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border-radius: 4px;
  }

  #container-1 {
    height: auto !important;
  }

  /* .action-panel {
      padding: 2px !important;
    } */
  .mat-card:not([class*="mat-elevation-z"]) {
    box-shadow: 0 4px 4px -1px #c5abc6, 0 4px 4px 0 #c5abc6, 0 4px 4px 0 #c5abc6;
  }

  .td-padding {
    td {
      padding: 0px !important;
    }
  }

  .td-padding-none {
    td {
      padding: 1px !important;
    }
  }

  .hide-visibility {
    visibility: collapse;
  }

  .material-icons {
    cursor: pointer;
  }

  // .input-fields {
  //   tr {
  //     height: 34px;
  //     min-height: 34px;
  //   }
  // }

  .not-empty-select.mat-select-value-text {
    color: #212529 !important;
    font-weight: bold !important;
  }

  ::ng-deep.mat-select-value-text {
    font-weight: bold;
  }

  ::ng-deep.mat-select-arrow {
    color: black;
  }

  .example-tree-invisible {
    display: none;
  }

  .example-tree ul,
  .example-tree li {
    margin-top: 0;
    margin-bottom: 0;
    list-style-type: none;
  }

  .example-form {
    min-width: 150px;
    max-width: 500px;
    width: 100%;
  }

  .example-full-width {
    width: 100%;
  }

  .cdk-overlay-pane,
  .mat-autocomplete-panel-above {
    // zoom: 70%;
  }

  .new-dropdown {
    border: none !important;
    border-bottom: 1px solid #ee4a6f !important;

    .dropdown-down {
      display: none !important;
    }
  }

  ::ng-deep.mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 2.2em;
  }

  ::ng-deep.multiselect-dropdown .dropdown-btn {
    border: none;
    border-bottom: 1px solid #ee4a6f;
  }

  .mat-form-field-label-wrapper {
    top: -16px;
  }

  .mat-form-field-appearance-legacy .mat-form-field-label span {
    font-size: 14px;
    color: #969696;
    font-weight: 600;
  }
}

#fb-forecast-grid {
  .row {
    .col-12 {
      input {
        text-align: left;
      }

      .float-right {
        width: 100%;

        i {
          position: relative;
          right: -20px;
          font-size: 14px;
          top: 3px;
        }
      }
    }
  }
}

//new dailog cs
.account {
  .mat-dialog-container {
    padding: 40px;
    border-radius: 30px;

    .mat-dialog-content {
      p {
        margin: 0;
        font-weight: bold;
      }
    }

    .action-panel {
      padding: 0;
      justify-content: space-between;
      margin: 0;

      &.btn-wrapper {
        .btn {
          height: 45px;
          border-radius: 10px;
          margin-left: 0;

          &.btn-secondary {
            padding: 0 45px;
          }

          &.btn-primary {
            padding: 0 50px;
          }
        }
      }
    }
  }
}

.fb-dialog-wrapper {
  .mat-dialog-container {
    padding: 40px 100px;
    border-radius: 30px;

    @media (min-width: 1900px) {
      padding: 100px 100px;
    }

    .mat-dialog-title {
      font-size: 18px;
      font-weight: bold;
      line-height: 27px;
      font-family: "Lato", sans-serif;

      @media (min-width: 1900px) {
        font-size: 22px;
      }
    }

    .mat-dialog-content {
      margin: 0;
      padding: 0;
      overflow: hidden;

      h2 {
        font-size: 12px;
        padding-bottom: 13px;
        margin: 0;
        border-bottom: 1px solid #707070;
      }

      p {
        font-size: 18px;
        font-weight: bold;
        line-height: 27px;
        margin-bottom: 35px;
        border-bottom: 1px solid #707070;
        padding-bottom: 35px;
        font-family: "Lato", sans-serif;

        @media (min-width: 1900px) {
          font-size: 22px;
        }
      }

      .radio-wrapper {
        display: flex;
        flex-direction: column;
        height: 378px;
        overflow-y: auto;
        padding-right: 7px;

        .mat-checkbox {
          padding: 8px 10px;
          height: 40px;

          &:nth-child(odd) {
            background-color: #f8f8f8;
          }

          .mat-checkbox-label {
            font-size: 16px;
            font-weight: bold;
            line-height: 19px;
            margin-left: 10px;
            font-family: "Lato", sans-serif;
          }
        }
      }

      .inner-data {
        border-left: 1px solid #707070;
        height: 378px;
        overflow-y: auto;
        padding-right: 7px;

        .main-label {
          padding: 8px 10px;
          margin: 0;
          height: 40px;

          &:nth-child(odd) {
            background-color: #f8f8f8;
          }

          .mat-checkbox-label {
            font-size: 16px;
            font-weight: bold;
            line-height: 19px;
            margin-left: 10px;
            white-space: normal;
            font-family: "Lato", sans-serif;
          }
        }
      }

      .inner-data::-webkit-scrollbar-track {
        border-radius: 8px;
        background-color: #e6e6e6;
      }

      .inner-data::-webkit-scrollbar {
        width: 14px;
        background-color: #e6e6e6;
      }

      .inner-data::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: #a2a2a2;
      }
    }

    .action-panel {
      padding: 0;
      justify-content: space-between;
      margin: 0;

      &.btn-wrapper {
        .btn {
          border-radius: 14px;
          height: 50px;
          width: 150px;
          min-width: 150px;
          font-size: 16px;
          font-weight: bold;
          font-family: "Lato", sans-serif;
          box-shadow: none;

          @media (min-width: 1900px) {
            height: 60px;
            width: 219px;
            min-width: 219px;
            font-size: 22px;
          }

          &.btn-secondary {
            background-color: transparent;
            color: #f7476d;
            border: 3px solid #f7476d;
          }

          &.btn-primary {
            background-color: #f7476d;
            color: #fff;
            margin-left: 20px;

            .mat-button-wrapper {
              justify-content: center;
            }
          }
        }
      }
    }
  }
}

.fb-dialog-wrapper2 {
  max-width: 700px !important;
  width: 700px !important;

  @media (min-width: 1900px) {
    max-width: 848px !important;
    width: 848px !important;
  }

  &.fb-dialog-wrapper3 {
    max-width: 780px !important;
    width: 780px !important;

    @media (min-width: 1900px) {
      max-width: 1000px !important;
      width: 1000px !important;
    }
  }

  &.fb-dialog-wrapper4 {
    max-width: 686px !important;
    width: 686px !important;

    @media (min-width: 1900px) {
      max-width: 686px !important;
      width: 686px !important;
    }

    .mat-dialog-container {
      .action-panel {
        justify-content: flex-start;
      }
    }
  }

  &.fb-dialog-wrapper5 {
    max-width: 596px !important;
    width: 596px !important;

    @media (min-width: 1900px) {
      max-width: 596px !important;
      width: 596px !important;
    }
  }

  .mat-dialog-container {
    padding: 40px 60px;
    border-radius: 30px;

    @media (min-width: 1900px) {
      padding: 60px 80px;
    }

    .mat-dialog-title {
      font-size: 18px;
      font-weight: bold;
      line-height: 27px;
      font-family: "Lato", sans-serif;

      @media (min-width: 1900px) {
        font-size: 22px;
      }
    }

    .mat-dialog-content {
      margin: 0;
      padding: 0;

      h2 {
        font-size: 12px;
        padding-bottom: 13px;
        margin: 0;
        border-bottom: 1px solid #707070;
      }

      p {
        font-size: 18px;
        font-weight: bold;
        line-height: 27px;
        margin-bottom: 25px;
        padding-bottom: 25px;
        border-bottom: 1px solid #707070;
        font-family: "Lato", sans-serif;

        @media (min-width: 1900px) {
          font-size: 22px;
          margin-bottom: 35px;
          padding-bottom: 35px;
        }
      }
    }

    .mat-form-field {
      .mat-form-field-wrapper {
        margin: 8px 0 0;
        padding-bottom: 35px;

        .mat-form-field-flex {
          padding: 0 20px;

          .mat-form-field-outline {
            background-color: #f4f4f4;

            .mat-form-field-outline-start,
            .mat-form-field-outline-gap,
            .mat-form-field-outline-end {
              display: none;
            }
          }

          .mat-form-field-infix {
            border: 0;
            padding: 20px 0;

            input::placeholder {
              font-size: 22px;
              font-family: "Lato", sans-serif;
              font-weight: normal;
              color: #302e31;
            }
          }
        }
      }
    }

    .action-panel {
      padding: 0;
      justify-content: center;
      margin: 0;

      &.btn-wrapper {
        .btn {
          border-radius: 14px;
          height: 50px;
          width: 150px;
          min-width: 150px;
          font-size: 16px;
          font-weight: bold;
          font-family: "Lato", sans-serif;
          box-shadow: none;

          @media (min-width: 1900px) {
            height: 60px;
            width: 190px;
            min-width: 190px;
            font-size: 22px;
          }

          &.btn-secondary {
            background-color: transparent;
            color: #f7476d;
            border: 3px solid #f7476d;
          }

          &.btn-primary {
            background-color: #f7476d;
            color: #fff;
            margin-left: 20px;

            &:first-child {
              margin-left: 0;
            }

            .mat-button-wrapper {
              justify-content: center;
            }
          }
        }
      }
    }
  }
}

.item-image {
  max-width: 100%;
  max-height: 100%;
}

#tem-manager {
  .ag-theme-material .ag-center-cols-viewport .ag-row .ag-cell {
    font-size: 12px;
    font-weight: bold;
  }
}

//admin setting css
.admin-wrapper {
  .mat-tab-body {
    margin: 0;
  }

  .ag-theme-material .ag-center-cols-viewport .ag-row .ag-cell {
    &[col-id="1"] {
      text-align: right !important;
    }
  }
}

.item-grid-thumbnail {
  width: 50px;
  margin: auto;
}

.grid-action-cell {
  span {
    border-radius: 50%;
    cursor: pointer;
    padding: 5px;
  }

  span:hover {
    box-shadow: 0 0px 1px 0 rgba(152, 10, 153, 68%);
  }
}

.section-title {
  margin-right: auto;
  font-weight: 500;
  font-size: large !important;
}

.product-grid-filter-row,
.promotion-grid-filter-row,
.approval-grid-filter-row {
  .mat-select-placeholder {
    font-weight: 500;
    font-size: 14px;
  }
}

.product-grid-filter-row {
  box-shadow: 0px 3px 3px rgba(63, 63, 63, 0.1);
  font-size: 12px;
}

.product-wrapper {
  h3 {
    &.title {
      font-size: 16px;
      font-weight: 500;
      margin: 20px 0;
    }
  }

  .mat-expansion-panel:not(.mat-expanded)
    .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
    background-color: #fff;
  }

  .mat-expansion-panel {
    .mat-expansion-panel-header {
      padding: 0 20px 0 60px;
      position: relative;

      .mat-expansion-indicator {
        position: absolute;
        left: 22px;
        top: 7px;

        &[style="transform: rotate(180deg);"] {
          top: 15px;
        }

        &:after {
          padding: 4px;
        }
      }

      .mat-expansion-panel-header-title {
        span {
          color: #8d009d;
        }
      }
    }

    .mat-expansion-panel-header-description {
      flex-grow: unset;
      margin: 0;

      a {
        padding-left: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }

      .delete-icon {
        padding-left: 5px;
      }
    }

    .mat-expansion-panel {
      .mat-expansion-panel-header {
        padding: 0 20px 0 100px;

        .mat-expansion-indicator {
          left: 60px;
        }
      }

      .mat-expansion-panel {
        .mat-expansion-panel-header {
          padding: 0 20px 0 140px;

          .mat-expansion-indicator {
            left: 100px;
          }
        }

        .mat-expansion-panel {
          .mat-expansion-panel-header {
            padding: 0 20px 0 180px;

            .mat-expansion-indicator {
              left: 140px;
            }
          }
        }
      }
    }
  }

  .single-link {
    padding: 0 20px 0 170px;
    background-color: #f3f3f3;

    &:hover {
      background-color: #fff;
    }

    a {
      display: flex;
      align-items: center;
    }

    .delete-icon {
      padding-top: 10px;
      padding-left: 10px;
    }

    &.child-links {
      padding: 0 20px 0 45px;
    }

    .mat-list-item {
      color: #000;
      font-weight: 600;
      font-size: 18px;
      font-family: "Lato", sans-serif;

      &:hover {
        background-color: transparent;
      }

      span {
        color: #8d009d;
        padding-left: 5px;
      }
    }
  }
}

//loading div
.loading-div {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
}

.error {
  border: 1px solid red !important;
}

.material-icons-round {
  cursor: pointer;
}

.insight-detail-wrapper {
  padding: 0 50px 0 80px;

  .insight-detail-title {
    border-top: 1px solid #d71ad1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    margin-top: 20px;
    padding-bottom: 20px;

    p,
    h5 {
      margin: 0;
    }

    h5 {
      font-size: 14px;

      span {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .insight-details-item-wrapper {
    .inner-div-left {
      border-bottom: 1px solid #000;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .inner-div {
      border-left: 1px solid #000;
    }

    .insight-detail-items {
      padding-left: 20px;
      padding-top: 10px;
      padding-bottom: 20px;
      border-bottom: 1px solid #000;

      p {
        margin-bottom: 5px;
        font-size: 12px;
      }

      h3 {
        margin: 0;
        font-size: 18px;
        font-weight: 500;
      }

      &.full {
        padding-left: 0;
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }

  .insight-detail-table {
    padding-left: 20px;
    padding-top: 20px;

    .insight-detail-table-head,
    .table-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;

      h6 {
        margin: 0;
        font-weight: 500;
        font-size: 15px;
      }
    }

    .insight-detail-table-head {
      background-color: #8d009d;
      border-radius: 15px 15px 0 0;
      height: 40px;

      h6 {
        color: #fff;
      }
    }

    .table-row {
      height: 30px;

      &:nth-child(even) {
        background-color: #f2f2f2;
      }
    }
  }

  .insight-graph-wrapper {
    padding-top: 30px;
  }
}

.grid-bar-icon i {
  font-size: 30px !important;
  margin-left: 0px !important;
}

.promotion-planner-detail-tabs {

  .promotion-planner-detail-tabs {
    width: 500px;
    margin-left: auto;
    z-index: 999;
  }

  .mat-tab-body-wrapper {
    margin-top: -50px;
    z-index: 0;
  }

  .mat-tab-header {
    width: 50%;
    border-bottom: none;
  }
}

.hide-grid {
  display: none;
}

.layout-exp-panel {
  padding-left: 0px !important;
  padding-right: 15px !important;
}

.treeview-container .row-item {
  .form-check {
    input {
      width: 18px;
      height: 18px;
    }
  }
}

// PLANOGRAM SCSS

.prv-week-upload-section {
  justify-content: flex-end;

  .upload-btn-label {
    margin: auto 0;
    font-size: large;
    font-weight: 500;
  }

  .upload-btn {
    margin-right: 20px;
    text-transform: uppercase;
  }
}

.purple-clr {
  color: #c31dd9;
}

.black-clr {
  color: #000;
}

.bg-gray {
  background: #f4f4f4;
}

.bg-yellow {
  background: yellow;
}

.bg-green {
  background: rgb(14, 160, 14);
}

.bg-blue {
  background: rgb(125, 192, 236);
}

.bg-red {
  background: rgb(235, 81, 81);
}

.bg-purple {
  background: #c31dd9;
}

.bg-orangered {
  background: rgb(255, 129, 0);
}

// PLANOGRAM STYLES END

.ag-overlay-loading-wrapper {
  justify-content: left;

  .ag-loading {
    height: 50%;
    margin-bottom: auto;
  }
}

//new item planer
.new-item-planner {
  border-top: 2px solid #d81ad1;
  padding-top: 40px !important;
  margin-top: 30px;

  h2 {
    color: #302e31;
    font-size: 14px;
    line-height: 19px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .padding-right,
  .padding-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .bottom-div {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .form-group {
        justify-content: flex-end !important;
        margin: 0 !important;

        label {
          min-width: 50px !important;
        }
      }
    }
  }

  .padding-right {
    border-right: 1px solid #cac8c8;
  }

  .padding-left {
    @media (min-width: 1600px) {
      padding-left: 40px;
    }
  }

  .item-wrapper {
    .btn-wrapper {
      margin: 0;
      padding: 0;
      width: 100%;

      .btn {
        margin: 0 !important;
        border-radius: 12px !important;
        font-weight: bold;
        font-family: "Lato", sans-serif;
        height: 45px !important;
        padding: 0 40px !important;
        line-height: 19px;
      }
    }
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #cac8c8;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(transparent, grey);
  border-radius: 5px;
}

// ::-webkit-scrollbar-thumb:hover {
//   background: linear-gradient(transparent, #cac8c8);
// }

.ag-center-cols-viewport {
  width: 100%;
  overflow-x: hidden !important;
}

.ag-theme-material .ag-ltr .ag-header-select-all {
  margin-right: 24px;
  margin-left: 24px;
}

.dropdown-text {
  .treeview-item {
    white-space: normal !important;
  }
}

.sticky {
  position: fixed;
  top: 56px;
  background-color: #fff;

  &.right-dropdown {
    width: 204px;
  }

  // &.left-dropdown{
  //   .scrolling-wrapper{
  //     width: 183px;
  //   }
  // }
}

.sticky2,
.sticky3 {
  margin-top: 47px;
  display: block;
}

.table-header-sticky {
  display: flex;
  align-items: center;
  margin-left: 11px;
  background-color: #fff;
  z-index: 1;

  .wrapper-header {
    overflow-x: scroll;
    display: flex;
    align-items: center;
    max-width: 632px;

    @media (min-width: 1600px) {
      max-width: 800px;
    }

    @media (min-width: 1900px) {
      max-width: 1016px;
    }

    @media (min-width: 2000px) {
      max-width: 1145px;
    }
  }

  .header-right-sticky {
    width: 195px;

    .mat-select {
      height: 50px;
      border-right: 1px solid;
      padding: 0 13px 0 18px;
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      margin-bottom: 0;
      width: 195px;

      .mat-select-value .mat-select-placeholder {
        color: #000;
      }

      .mat-select-arrow {
        color: black !important;
        border-style: solid;
        border-width: 0px 3px 3px 0px;
        transform: rotate(45deg);
        border-right: 3px solid #0d0d0d;
        padding: 5px;
        margin: -5px 0 0 0;
      }

      .mat-select-trigger {
        height: 100%;

        .mat-select-value {
          vertical-align: middle;
          text-align: left;
        }
      }
    }
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.wrapper-header::-webkit-scrollbar {
  display: none;
}

.Global-data-row,
.regional-data-row,
.wrapper-header {
  max-width: 632px;

  @media (min-width: 1600px) {
    max-width: 800px;
  }

  @media (min-width: 1900px) {
    max-width: 1016px;
  }

  @media (min-width: 2000px) {
    max-width: 1145px;
  }
}

.planogram-tooltip {
  white-space: pre-line;
}

.invalid-cell-value {
  border: 1px solid red !important;
  color: red;
}

.channel-menu-list {
  .mat-line {
    padding: 15px 0 !important;
  }

  .channel-logo {
    float: left;
    max-width: 18%;
    margin-top: 3px;
    margin-left: 10px;
  }

  .unread-msg-count {
    position: absolute;
    bottom: 15px;
    margin-left: 35px;
  }
}

.chat-msgs-box {
  height: 50vh;
  overflow-y: scroll;
}

.msg-box {
  display: inline-flex;
  width: 100%;

  .chat-block-border-radius {
    min-width: 300px;
    max-width: 100%;
    width: calc(100% - 75px);
    border: 1px solid darkgray;
    border-radius: 10px;
    padding: 10px;

    .user-name {
      font-weight: 500;
    }
  }

  .chat-time {
    margin: auto 10px;
    min-width: 60px;
  }

  .actions-buttons {
    text-align: end;
    margin-top: -36px;
  }

  textarea {
  }
}

.chat-textarea {
  .mat-form-field-outline-thick {
    color: #ec4173 !important;
    border: 3px solid;
    border-radius: 5px;

    .mat-form-field-outline-start,
    .mat-form-field-outline-gap,
    .mat-form-field-outline-end {
      border: none !important;
    }
  }

  .mat-form-field-suffix {
    top: 1.25em !important;
  }

  .mat-icon {
    font-size: 30px;
  }
}

// CURSOR POINTER CLASS
.cursor-pointer {
  cursor: pointer !important;
}

.channel-info-container {
  .channel-member-info {
    cursor: pointer;
  }
}

.vertical-middle {
  vertical-align: middle;
}

.cdk-overlay-container {
  .cdk-overlay-pane {
    .orderClass {
      margin-top: 60px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      box-shadow: none;
      zoom: 80%;
      margin-left: 7px;
      border-radius: 0;

      .mat-option {
        font-size: 1rem !important;
      }
    }
  }
}

.outlier-manager-page {
  .row-info {
    button {
      margin-left: 10px;
      margin-right: 20px;
    }
  }

  .filtered-info {
    padding-left: 30px;
    border-left: 1px solid darkgrey;
  }

  .inline-flex {
    display: inline-flex;
  }
}

.action-btn-bar {
  width: 100%;
  display: inline-flex;

  .toggle-btn {
    font-weight: 600;
    font-size: medium;
    margin: auto 0;
  }

  .save-btn {
    margin-left: auto;
  }

  button {
    margin-left: 10px;
    margin-top: 10px;
  }
}

.ag-week-col {
  display: block !important;
  align-self: auto !important;
  text-align: center;
}

.commit-grid {
  .ag-horizontal-left-spacer,
  .ag-body-horizontal-scroll-viewport,
  .ag-body-horizontal-scroll-container,
  .ag-body-horizontal-scroll {
    overflow-x: hidden !important;
    height: 0px !important;
    max-height: 0px !important;
    min-height: 0px !important;
  }

  .ag-body-viewport {
    overflow-y: hidden !important;
  }

  .ag-center-cols-viewport {
    height: 100% !important;
  }
}

.outlier-grid {
  .row-span {
    color: #fff;
  }

  .ag-cell {
    border-top: none;
    border-bottom: none;
  }

  .ag-row-hover {
    background-color: transparent;
  }

  .clear-top-border {
    border-top: none;
  }

  .selection-box {
    padding-bottom: 40px;
  }

  .ag-cell-focus,
  .ag-cell-no-focus {
    border: none !important;
  }

  .ag-cell:focus {
    border: none !important;
    outline: none;
  }

  .sku-info-row {
    padding-top: 20px;
  }

  .mapped-value-cell {
    color: red;
  }

  .adj-background {
    background-color: #f3f3f3;
  }

  .adjusted-row {
    border-bottom: 1px solid darkgray;
  }

  .adjusted-outlier {
    background-color: #29ab87;
    color: #fff;
    font-weight: 800;
  }

  .commited-org-data {
    color: #3f51b5;
  }

  .unadjusted-outlier {
    background-color: #ff5349;
    color: #fff;
    font-weight: 800;
  }

  .column-edited {
    border: double;
  }

  .trend-chart-cell {
    padding: 0;
  }

  .edited-cell {
    background-color: yellow;
    font-weight: 800;
    color: red;
  }

  .unadjusted-column-group {

    display: grid;

    .ag-header-group-text {
      color: #ff4081;
    }

    button {
      padding: 0;
      line-height: 25px;
      margin-top: 5px;
    }
  }

  .ty-column-group {
    background-color: #ff4081;

    &:hover {
      background-color: #ff4081 !important;
    }

    .ag-header-group-text {
      color: #fff;
      margin: auto;
    }
  }

  .ly-column-group {
    background-color: #203764;

    &:hover {
      background-color: #203764 !important;
    }

    .ag-header-group-text {
      color: #fff;
      margin: auto;
    }
  }

  .chart-cell {
    // margin-top: -50px;
    // height: 100px;
  }
}

.client-button {
  display: block;
  margin: 20px;

  button {
    font-size: large;
    font-weight: 600;
    width: 100%;
    text-align: left;
  }
}

.mapping-manager-page {
  .mapping-action-btn-bar {
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
    padding: 10px 0;
  }

  .grid-actions {
    display: flex;
    margin-right: auto;

    .filtered-info {
      padding-left: 15px;
      border-left: 1px solid grey;
    }
  }

  .grid-bar-icon {
    margin-right: 10px;
  }
}

.mapping-manager-dialog {
  width: 100%;
  margin: auto;

  .mat-dialog-content {
    max-height: 900px;
  }

  .dialog-close-btn {
    display: inline-flex;
    width: 100%;
  }

  .dialog-close-btn button {
    margin-left: auto;
    background-color: #000;
    color: #fff;
  }

  .mapping-2-box {
    padding-left: 50px;
    border-left: 1px solid grey;
  }

  hr {
    border-color: #ff4081;
    border-style: dotted;
  }

  .mapping-history-tbl,
  .mapping-tbl {
    width: 100%;

    tbody {
      td,
      th {
        padding: 5px;
      }

      td {
        background-color: #dddddd;
        border: 1px solid #fff;
      }

      td:last-child {
        background-color: transparent;
      }
    }
  }

  .mapping-action-btn {
    justify-content: flex-end;
    margin: 20px auto;
  }

  .plus-map-item-btn {
    button {
      background-color: #000;
      color: #fff;
    }
  }

  .w-10 {
    width: 10%;
  }

  .w-20 {
    width: 20%;
  }

  .w-100 {
    width: 100%;
  }

  .average-cell {
    padding: 5px;
  }
}

.dialog-close-btn {
  display: inline-flex;
  width: 100%;
}

.dialog-close-btn button {
  margin-left: auto;
  background-color: #000;
  color: #fff;
}

.mr-10 {
  margin-right: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.grid-action-btn-bar {
  width: 100%;
  display: inline-flex;
  justify-content: flex-end;
  padding: 10px 0;

  .title-label {
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    font-size: large;
    font-weight: 500;
  }

  .grid-actions {
    display: flex;
    margin-right: auto;

    .filtered-info {
      padding-left: 15px;
      border-left: 1px solid grey;
    }
  }

  .toggle-btn {
    font-weight: 600;
    font-size: medium;
    margin: auto 0;
  }

  .mh-auto {
    margin: auto 0;
  }
}

.sync-msg {
  color: red;
  white-space: nowrap;
}

.mt-10 {
  margin-top: 10px;
}

.error-field {
  border: 1px solid red !important;
}

.add-icon-btn {
  .mat-icon-button {
    box-shadow: 0 3px 8px 0 rgba($secondary, 0.68);
    margin-left: 10px;
  }

  .material-icons {
    font-size: 50px;
    color: $secondary;
    cursor: pointer;
    margin-left: -4px;
  }

  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.inline-flex {
  display: inline-flex;
}

.grid-display {
  display: grid;
}

.radio-btn-grp {
  label {
    width: 100% !important;
    display: inline-flex !important;
  }
}

.suggestion-box {
  border: 1px solid #ff4081;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.trend-action {
  font-size: 12px;
  padding: inherit !important;
}

.mat-dialog-actions-section {
  justify-content: flex-end;
  margin: 20px auto;
}

.promo-toggle-btn {
  .mat-slide-toggle-label {
    display: inline-flex !important;
  }
}

.trend-section {
  display: inline-flex;
  width: 100%;

  label {
    color: #302e31;
    font-size: 12px;
    line-height: 19px;
    min-width: 140px;
    margin: 0;
    width: 130px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .week-values {
    display: block;
  }

  .week-index,
  .week-input {
    width: 70px;
  }

  .week-index {
    background-color: #f4f4f4;
    padding: 5px;
  }

  .week-input {
    border: 1px dotted #ff4081;
  }

  .add-icon-btn {
    margin: auto;
  }

  .trend-week-btn {
    display: grid;

    button {
      margin: auto;
    }
  }

  .mat-icon-button {
    height: 30px;
    width: 30px;
    line-height: 30px;
  }

  .material-icons {
    font-size: 30px;
  }

  .weeks-section {
    overflow-x: auto;
    width: 100%;
    display: inline-flex;
  }
}

.trend-view-title {
  background-color: aliceblue;
  padding: 5px;
  margin: 10px auto;
  font-weight: 600;
}

.promo-item-dialog {
  width: 1000px;
  height: auto;

  .promo-title {
    margin: auto;
    padding: 5px;
    background: aliceblue;
    border-radius: 5px;
    font-weight: 500;
  }
}

.depromo-grid-week-icon-btn {
  display: inline-flex;

  .mat-icon-button {
    height: 30px;
    width: 30px;
    line-height: 30px;
  }

  .value-display {
    margin: auto 10px;
    font-weight: 600;
    font-size: 15px;
  }
}

.form-action-bar {
  text-align: end;
  margin: 10px auto;
}

.uplift-section,
.decay-section {
  border: 1px dotted #ff4081;
  padding: 10px;
}

.promo-item-dialog {
  .ag-root-wrapper.ag-layout-normal {
    height: calc(50vh) !important;
  }
}

.seasonality-weeks-data-grid {
  .ag-root-wrapper.ag-layout-normal {
    height: calc(30vh) !important;
  }
}

.unmapped-skus-grid {
  .ag-root-wrapper.ag-layout-normal {
    height: calc(55vh) !important;
  }
}


.form-group-section {
  border: 1px dotted #ff4081;
  padding: 10px;
}

.weeks-section {
  overflow-x: auto;
  width: 480px;
  display: inline-flex;

  .week-index {
    text-align: center;
    background-color: lightgray;
  }

  .week-input {
    width: 80px;
  }
}

.dialog-action-btn {
  justify-content: flex-end;
  margin: 20px auto;
}

.form-group.multi-select {
  ul.item2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .multiselect-dropdown .dropdown-btn {
    border-bottom: none !important;
  }

  ng-multiselect-dropdown {
    width: 100%;
    background: #f4f4f4;
    padding: 10px 5px 0 7px;
    min-height: 35px;
  }

  .arrow-down {
    border-style: solid;
    border-width: 0px 2px 2px 0;
    content: "";
    display: inline-block;
    padding: 4px;
    color: #000 !important;
    transform: rotate(45deg);
    width: inherit;
    height: inherit;
    margin: 4px;
    border-left: unset;
    border-right: 2px solid;
  }

  .filter-textbox {
    width: 100% !important;
  }
}

.top-border {
  border-top: 1px solid darkgray;
}

.trend-chart-cell {
  padding: 0 !important;
}

.promo-manager-grid {
  .ag-set-filter-list {
    width: 350px !important;
  }
}

.mt-5px {
  margin-top: 5px;
}

#forecast-manager-matrix-grid {
  .ag-header-cell {
    padding-left: 5px;
    padding-right: 5px;
  }

  .ag-cell {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.forecast-engine-ag-grid {
  .ag-theme-material .ag-header-cell,
  .ag-theme-material .ag-header-group-cell {
    padding-left: 10px;
    padding-right: 10px;
  }

  .ag-theme-material .ag-cell,
  .ag-theme-material .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    border: 1px solid transparent;
    line-height: 46px;
    padding-left: 10px;
    padding-right: 10px;
    -webkit-font-smoothing: subpixel-antialiased;
  }
}

// sync button forecast manager: Style START
.sync-btn {
  color: white !important;
  transition: 0.2s all;
  background-color: #2dc12da6 !important;
}
.sync-btn.in-progress {
  background-color: #1a26cccc !important;
}
.sync-btn.failed {
  background-color: #cc1a1acc !important;
}
.sync-btn.queued {
  background-color: #ffac35 !important;
}
.sync-btn:hover {
  scale: 1.1;
}
// sync button forecast manager: Style END
.decay-column-group {  
  background-color: antiquewhite;
  border-left: 1px solid #ff4081;
  border-right: 1px solid #ff4081;

  .ag-header-group-cell-label {
    justify-content: center;
  }
}

